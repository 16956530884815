import React from 'react'
import CardItemEvents from './CardItemEvents'
import "../css/Cards.css";

const Cards = (props) => {
  const events = Object.values(props.events);

  const showCards = events.map(data => {
    // const showCards = events.map(data => {

    return (<CardItemEvents
      text={data.title}
      date={data.start}
      timestart={data.startTijd}
      timeend={data.endTijd}
      key={data.id} />
    )
  });

  return (
    <div className='cards__container'>
      <div className='cards__wrappper'>
        <ul className='cards__items'>
          {showCards}
        </ul>
      </div>
    </div>
  )
}

export default Cards
