import { useState, useEffect } from 'react';
import api from "../api/users";

const ChangePassword = (props) => {
    const initialValues = { currentPassword: "", newPassword: "", repeatNewPassword: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [validChange, setValidChange] = useState(false);
    const [changeFailed, setChangeFailed] = useState("");
    const [success, setSuccess] = useState("");

    const handleCloseTask = () => {
        props.setClickedPW(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true)
    }

    const checkDatabase = async () => {
        setSuccess("");
        setChangeFailed("");
        const response = await api.post("/users/password", { userId: props.id, pwd: formValues, firstLogin: props.firstLogin })

        if (response.data.valid === true) {
            setValidChange(true)
        } else {
            setValidChange(false);
            setChangeFailed("Huidige wachtwoord klopt niet");
        }
    }

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            checkDatabase();
            setFormErrors({});
            setIsSubmit(false)
        }
        // eslint-disable-next-line
    }, [formErrors])

    useEffect(() => {
        if (validChange) {
            setChangeFailed("");
            setFormValues(initialValues);
            setIsSubmit(false)
            setValidChange(false);
            setSuccess("Wachtwoord is veranderd!");

            setTimeout(() => {
                props.setFirstLogin && props.setFirstLogin(0);
                props.setClickedPW(false);
            }, 2000);
        }
        // eslint-disable-next-line
    }, [checkDatabase])

    const validate = (values) => {
        const errors = {};

        if (!values.currentPassword || !values.newPassword || !values.repeatNewPassword) {
            errors.error = "Velden met een * zijn verplicht!";
        }
        else {
            if (values.newPassword.length < 4) {
                errors.error = "Wachtwoord moet langer zijn!";
            }

            if (values.newPassword !== values.repeatNewPassword) {
                errors.error = "Wachtwoorden komen niet overeen!";
            }
        }

        return errors;
    }

    return (
        <>
            <div className='taakverdelingItem'>
                <div className='taakverdelingItemToggle'>
                    <button onClick={handleCloseTask}>X</button>
                </div>
                <div className='taakverdelingItemHeader'>
                    <h1>Wijzig je wachtwoord</h1>
                </div>
                <div className='error'>{formErrors ? formErrors.error : null}</div>
                <div className='error'>{changeFailed ? changeFailed : null}</div>
                <div className='success'>{success ? success : null}</div>

                <form onSubmit={handleSubmit}>
                    <label className="taakverdelingItemNormal">* Huidige wachtwoord:</label>
                    <input
                        type="password"
                        name="currentPassword"
                        value={formValues.currentPassword}
                        placeholder="Huidige wachtwoord *"
                        onChange={handleChange}
                    />

                    <label className="taakverdelingItemNormal">* Nieuw wachtwoord:</label>
                    <input
                        type="password"
                        name="newPassword"
                        value={formValues.newPassword}
                        placeholder="Nieuw wachtwoord *"
                        onChange={handleChange}
                    />

                    <label className="taakverdelingItemNormal">* Herhaal nieuw wachtwoord:</label>
                    <input
                        type="password"
                        name="repeatNewPassword"
                        value={formValues.repeatNewPassword}
                        placeholder="Herhaal nieuw wachtwoord *"
                        onChange={handleChange}
                    />

                    <label className="taakverdelingItemNormal"></label>
                    <input type="submit" value="Wijzig wachtwoord" />

                </form>
            </div>
        </>
    )
}

export default ChangePassword
