import "../css/Panel.css";
import api from "../api/users";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { v4 as uuid } from 'uuid';
import ConfirmationModal from "./modal/ConfirmationModal";
import { isAdmin, isBestuur } from "./check/RightsCheck";

const Ledenlijst = (props) => {
    const [users, setUsers] = useState([]);
    const [usersChecked, setUsersChecked] = useState(false);
    const [rechten, setRechten] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showRoleModal, setShowRoleModal] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState(0)
    const [userId, setUserId] = useState(0)
    const [userRechten, setUserRechten] = useState([]);

    const getUserList = async () => {
        if (Object.keys(users).length === 0 && !usersChecked) {
            const users = await api.get("/users/getuserlist");
            setUsers(users.data);
            setUsersChecked(true);
        }
    }

    const getRoles = async () => {
        const apiRoles = await api.post("/users/rights", { userId: userId });
        setUserRechten(apiRoles.data)
    }

    const openModal = (e) => {
        setShowModal(true);
        setDeleteUserId(e.target.value);
    }

    const openRoleModal = async (e) => {
        setShowRoleModal(true);
        setUserId(e.target.value);
    }

    useEffect(() => {
        getRoles();
        fetchRechten();
        // eslint-disable-next-line
    }, [userId])

    const handleClose = () => {
        setShowModal(false);
    }

    const handleRoleClose = () => {
        setShowRoleModal(false);
    }

    const handleRoleSave = async () => {
        const saveRechten = await api.post("users/updaterights", { userId: userId, rights: userRechten, callingUserId: props.userDetails.id, callingUserEmail: props.userDetails.email });

        if (saveRechten.data) {
            setShowRoleModal(false);
            props.setSuccessMsg("Rechten van lid zijn aangepast!");
        }
    }

    useEffect(() => {
        getUserList();
    })

    // @todo, fetch rechten from database
    const fetchRechten = async () => {
        const rechtenApi = await api.get("users/fetchrights");

        if (rechtenApi.data) {
            setRechten(rechtenApi.data);
        }
    }

    const handleCheck = (position) => {

        const changedRechten = userRechten.map((item, index) =>
            index === position ? !item : item
        );

        setUserRechten(changedRechten);
    };

    const checkList = Object.values(rechten).map((({ recht, id }, index) => {
        return (
            <li className="checkbox" key={index}>
                <input
                    type="checkbox"
                    id={`custom-checkbox-${id}`}
                    name={recht}
                    value={index}
                    checked={userRechten[index]}
                    onChange={() => handleCheck(index)}
                />
                <label htmlFor={`custom-checkbox-${index}`}>&nbsp;{recht}</label>
            </li>
        );
    }))

    const mapUsers = users.map((({ id, name, email, lastLogin }) => {
        if (lastLogin === null) {
            lastLogin = <i><u>Nog nooit ingelogd</u></i>
        }

        return (
            <>
                {showModal ? <ConfirmationModal deleteId={deleteUserId} userId={props.userDetails.id} type="user" show={showModal} setShow={setShowModal} setSuccess={props.setSuccessMsg} setFail={props.setFailedMsg} handleClose={handleClose} closeUserList={props.setUserListClick} /> : null}

                <Modal show={showRoleModal} onHide={handleRoleClose}>
                    <Modal.Header>
                        <Modal.Title>Verander rollen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Verander hier de rollen van dit lid</Modal.Body>
                    <Modal.Body>{checkList}</Modal.Body>
                    <Modal.Footer>
                        <button variant="primary" onClick={handleRoleSave}>Opslaan</button>
                        <button variant="primary" onClick={handleRoleClose}>Sluiten</button>
                    </Modal.Footer>
                </Modal>

                <div className="grid-ledenlijst-items" key={id + name}>
                    {name}
                </div>
                <div className="grid-ledenlijst-items" key={id + email}>
                    {email}
                </div>
                <div className="grid-ledenlijst-items" key={id + lastLogin}>
                    {lastLogin}
                </div>
                {isAdmin() || isBestuur() ?
                    <>
                        <div className="grid-ledenlijst-items" key={uuid()}>
                            <button className="changeRole" onClick={openRoleModal} value={id}>Verander rol</button>
                        </div>
                        <div className="grid-ledenlijst-items" key={uuid()}>
                            <button className="panelDeleteButton" style={{ marginTop: "0rem" }} onClick={openModal} value={id}>X</button>
                        </div>
                    </>
                    :
                    null
                }
            </>
        );
    }))

    getUserList();

    return (
        <>
            <div className='panelItem'>
                <div className='panelItemToggle'>
                    <button onClick={() => props.setUserListClick(false)}>X</button>
                </div>
                <div className='panelItemHeader'>
                    <h1>Ledenlijst</h1>
                </div>

                <div className={isAdmin() || isBestuur() ? "grid-ledenlijst-admin" : "grid-ledenlijst-general"}>
                    <div> Naam </div>
                    <div> Email</div>
                    <div> Laatste bezoek </div>
                    {isAdmin() || isBestuur() ?
                        <>
                            <div> Verander rol </div>
                            <div> Verwijder </div>
                        </>
                        : null
                    }
                    {mapUsers}
                </div>
            </div>
        </>
    )
};

export default Ledenlijst
