import { useEffect, useState } from "react";
import api from "../api/users";
import HeaderList from "./HeaderList";
import BodyList from "./BodyList";
import CreateExcelFromArray from "./excel/CreateExcelFromArray";
import { isAdmin, isBestuur, isTaakverdeling } from "./check/RightsCheck";

const TakenOverzicht = (props) => {
    const [takenVisible, setTakenVisible] = useState(false);
    const [events, setEvents] = useState([]);
    const [eventsFetched, setEventsFetched] = useState(false);
    const [taakverdeling, setTaakverdeling] = useState([]);
    const [takenLijst, setTakenLijst] = useState([]);
    const [event, setEvent] = useState("");
    const [eventId, setEventId] = useState(0);
    const [activitySelected, setActivitySelected] = useState(false);
    const [fileName, setFileName] = useState("");

    const getActiviteiten = async () => {
        const apiEvents = await api.get("/agenda/taakverdeling");

        if (apiEvents.data !== null) {
            setEvents(apiEvents.data)
        }

        setEventsFetched(true);
    }

    useEffect(() => {
        if (!eventsFetched) {
            getActiviteiten();
        }
    });

    const activiteiten = events.map((events) => {
        return (
            <option key={events.id} value={events.id}>{events.title}</option>
        )
    });

    const handleSelectChange = (e) => {
        setEvent(e.target.selectedOptions[0].text);
        setEventId(e.target.value);
        setActivitySelected(true);
        setTakenVisible(true);
    }

    useEffect(() => {
        const getTakenoverzicht = async () => {
            const apiTaken = await api.get("/taken/gettakenoverzicht/" + eventId);
            if (apiTaken.data !== null) {
                setTaakverdeling(apiTaken.data);
                setTakenLijst(apiTaken.data[0]);
            }
        }

        getTakenoverzicht();

        setFileName("Taakverdeling " + event + " " + events.filter(e => e.id === eventId)[0]?.start)

        // eslint-disable-next-line
    }, [eventId])

    const createGrid = (length) => {
        let css = "auto / 11%"

        for (let i = 1; i < length; i++) {
            css += " 17%"
        }

        return css;
    }

    if (isAdmin() || isTaakverdeling() || isBestuur()) {
        return (
            <>
                <div className='panelItem'>
                    <div className='panelItemToggle'>
                        <button onClick={() => props.setTakenListClick(false)}>X</button>
                    </div>
                    <div className='panelItemHeader'>
                        <h1>Takenoverzicht</h1>
                    </div>

                    <div className="panelTakenDropdown">
                        <select onChange={handleSelectChange}>
                            <option
                                value="select"
                                key="select"
                                disabled={takenVisible}>
                                Kies een activiteit</option>
                            {activiteiten}
                        </select>
                    </div>

                    <div className="panelTakenList">
                        <div className="grid-taakverdelingtitel">
                            <h2>
                                {event}
                            </h2>
                        </div>
                        {eventId !== 0 ?
                            <div className="grid-export">
                                <CreateExcelFromArray fileName={fileName} eventId={eventId} />
                            </div>
                            : null
                        }

                        <div className="grid-taakverdeling" style={{ grid: createGrid(taakverdeling[0]?.length > 0 ? taakverdeling[0]?.length : 6) }}>
                            {activitySelected ?
                                <>
                                    <HeaderList headers={takenLijst} />
                                    <BodyList body={taakverdeling} />
                                </>
                                : null}
                        </div>
                    </div>
                </div>
            </>
        )
    }
};

export default TakenOverzicht