import { useState, useEffect } from 'react'
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from "../api/users";
import { isAdmin, isTaakverdeling } from './check/RightsCheck';
import AgendaTaskTimesModal from './modal/AgendaTaskTimesModal';
import AgendaTimesModal from './modal/AgendaTimesModal';
import { styled } from "styled-components";

const Button = styled.button`
    padding: 0.3rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border: 0.15rem solid #d90000;
    background-color: transparent;
    border-radius: 0.25rem;
    &:hover {
        border: 0.15rem solid #d90000;
        background-color: #d90000;
    }
`

const EditEvent = (props) => {
    let id = parseInt(props.eventId);
    const initialValues = {
        id: id,
        title: props.title,
        actType: parseInt(props.eventType),
        start: props.dayStart,
        end: props.dayEnd,
        startTijd: props.startTijd,
        endTijd: props.endTijd,
        description: props.description,
        taak: parseInt(props.taak),
        userId: props.userDetails.id,
        userEmail: props.userDetails.email
    };

    // const [checkedTaken, setCheckedTaken] = useState([]);
    // const [checkedTijden, setCheckedTijden] = useState([]);
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [startDate, setStartDate] = useState(new Date(props.dayStart));
    const [endDate, setEndDate] = useState(new Date(props.dayEnd));
    const [activityVisible, setActivityVisible] = useState(false);
    const [typeActiviteit, setTypeActiviteit] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [showTaskTimes, setShowTaskTimes] = useState(false);
    const [showTimes, setShowTimes] = useState(false);
    const [taskId, setTaskId] = useState();

    const getActivities = async () => {
        if (Object.keys(typeActiviteit).length === 0) {
            const apiTypes = await api.get("/agenda/type");
            setTypeActiviteit(apiTypes.data);
        }
    }

    const fetchChecked = async () => {
        const tijdenEventApi = await api.get("/agenda/getchecked/" + id);

        setFormValues({ ...formValues, taken: tijdenEventApi.data });
    }

    const fetchEventData = () => {
        getActivities();
        fetchChecked();
        setLoaded(true);
    }

    useEffect(() => {
        if (Object.values(formValues).length === 11 || !loaded) {
            fetchEventData();
        } else {
            setFormValues(initialValues);
        }
        // eslint-disable-next-line
    }, [props.eventId, loaded])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        props.setSuccess("");
    }

    const handleCloseTask = () => {
        props.setEditClicked(prevClicked => !prevClicked);
    }

    const handleSelectChange = (e) => {
        setActivityVisible(true);
        setFormValues({ ...formValues, "actType": parseInt(e.target.value) })
    }

    const onStartDateChange = (date) => {
        let formattedDate = new Date(date)
        setStartDate(formattedDate);
        setEndDate(formattedDate);
        formattedDate = format(formattedDate, "yyyy-MM-dd")

        setFormValues({ ...formValues, "start": formattedDate, "end": formattedDate })
        setFormErrors({});
    };

    const onEndDateChange = (date) => {
        let formattedEndDate = new Date(date)
        setEndDate(formattedEndDate);
        formattedEndDate = format(formattedEndDate, "yyyy-MM-dd")

        setFormValues({ ...formValues, "end": formattedEndDate })
        setFormErrors({});
    };

    const activityType = Object.values(typeActiviteit).map((activity) => {
        return (
            <option key={activity.id} style={{ background: activity.color }} value={activity.id}>{activity.type}</option>
        )
    });


    const changeAgenda = async () => {
        try {
            const change = await api.post("/agenda/change", formValues)
            if (change.data > 0) {
                props.setSuccess("Het agendapunt is gewijzigd!");
                props.setEditClicked(false);
                props.setAgendaItems({})
            } else {
                props.setFail("Er is iets mis gegaan, probeer het opnieuw")
            }
        } catch (err) {
            props.setFail("Er is iets mis gegaan, probeer het opnieuw")
            window.scrollTo(0, 0)
        }
    }

    const handleCheck = (e) => {
        props.setSuccess("");
        props.setFail("");
        if (e.target.checked) {
            setFormValues({ ...formValues, "taak": 1 })
        } else {
            setFormValues({ ...formValues, "taak": 0 })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setFormErrors(validate(formValues));
        setIsSubmit(true)
    }

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            changeAgenda();
            window.scrollTo(0, 0);

            setStartDate(new Date());
            setEndDate(new Date());
            setFormErrors({});
            setFormValues(initialValues);
            setActivityVisible(false);
            setIsSubmit(false);
        }
        // eslint-disable-next-line
    }, [formErrors, isSubmit])

    const validate = (values) => {
        const errors = {};
        if (!values.title || values.actType === 0 || !values.start || !values.startTijd) {
            errors.error = "Velden met een * zijn verplicht!";
        }

        if (values.startTijd.length <= 4 && values.startTijd.indexOf(":") < 1) {
            errors.error = "Voer aub de tijd correct in, bijvoorbeeld: 20:00"
        }

        if (values.endTijd.length > 0 && values.endTijd.indexOf(":") < 1) {
            errors.error = "Voer aub de tijd correct in, bijvoorbeeld: 21:00"
        }

        if (!values.endTijd) {
            let result = values.startTijd;

            if (errors.error > 0) {
                result = "";
                setFormValues({ ...formValues, "endTijd": result })
            }

            if (result.indexOf(':') !== -1) {
                let eind = values.startTijd.split(':')[0];
                let afterColon = values.startTijd.split(":")[1];

                if (eind < 21) {
                    eind = parseInt(eind) + 3;
                }
                else if (eind < 22) {
                    eind = parseInt(eind) + 2;
                }
                else if (eind < 23) {
                    eind = parseInt(eind) + 1;
                }

                result = eind.toString() + ":" + afterColon;
                setFormValues({ ...formValues, "endTijd": result })
            }
        }

        return errors;
    }

    const disabled = isTaakverdeling() && "disabled";

    const editTaskTimes = (e) => {
        e.preventDefault();

        setShowTaskTimes(true);
    }

    return (
        <>
            {showTaskTimes ? <AgendaTaskTimesModal setTaskId={setTaskId} show={showTaskTimes} setShow={setShowTaskTimes} setShowTimes={setShowTimes} formValues={formValues} setFormValues={setFormValues} /> : null}
            {showTimes ? <AgendaTimesModal taskId={taskId} show={showTimes} setShow={setShowTimes} setShowTask={setShowTaskTimes} formValues={formValues} setFormValues={setFormValues} /> : null}

            <div className='taakverdelingItem'>
                <div className='taakverdelingItemToggle'>
                    <button onClick={handleCloseTask}>X</button>
                </div>
                <div className='taakverdelingItemHeader'>
                    <h1> Wijzig {props.title} </h1>
                </div>
                <div className='error'>{formErrors ? formErrors.error : null}</div>
                <form onSubmit={handleSubmit}>
                    <label className={Object.keys(formErrors).length > 0 ? "taakverdelingItemReq" : "taakverdelingItemNormal"}>* Agenda punt</label>
                    <input
                        type="text"
                        name="title"
                        value={formValues.title}
                        placeholder="Naam van activiteit *"
                        onChange={handleChange}
                        disabled={disabled}
                    />

                    <label className={Object.keys(formErrors).length > 0 ? "taakverdelingItemReq" : "taakverdelingItemNormal"}>* Soort activiteit</label>
                    <select
                        value={formValues.actType}
                        name="actType"
                        onChange={handleSelectChange}
                        disabled={disabled}
                    >
                        <option disabled={activityVisible}>
                            Soort activiteit
                        </option>
                        {activityType}
                    </select>

                    <label className={Object.keys(formErrors).length > 0 ? "taakverdelingItemReq" : "taakverdelingItemNormal"}>* Start datum</label>
                    <DatePicker
                        locale="nl"
                        dateFormat="yyyy-MM-dd"
                        selected={startDate}
                        onChange={onStartDateChange}
                        showWeekNumbers
                        disabledKeyboardNavigation
                        name="startDate"
                        disabled={disabled}
                    />

                    <label className="taakverdelingItemNormal">* Eind datum</label>
                    <DatePicker
                        locale="nl"
                        dateFormat="yyyy-MM-dd"
                        selected={endDate}
                        onChange={onEndDateChange}
                        showWeekNumbers
                        disabledKeyboardNavigation
                        name="endDate"
                        disabled={disabled}
                    />

                    <label className={Object.keys(formErrors).length > 0 ? "taakverdelingItemReq" : "taakverdelingItemNormal"}>* Begin tijd   </label>
                    <input
                        type="text"
                        name="startTijd"
                        value={formValues.startTijd}
                        placeholder="Begin tijd? bv: 20:00 *"
                        onChange={handleChange}
                        disabled={disabled}
                    />

                    <label className="taakverdelingItemNormal">Eind tijd</label>
                    <input
                        type="text"
                        name="endTijd"
                        value={formValues.endTijd}
                        placeholder="Eind tijd? bv: 23:00"
                        onChange={handleChange}
                        disabled={disabled}
                    />

                    <label className="taakverdelingItemNormal">Omschrijving</label>
                    <textarea
                        type="text"
                        name="description"
                        value={formValues.description}
                        placeholder="Voeg eventueel een omschrijving / begeleidende tekst toe"
                        onChange={handleChange}
                        disabled={disabled}
                    />

                    {isAdmin() || isTaakverdeling() ?
                        <>
                            <label className="taakverdelingItemNormal">Taken nodig?</label>
                            <input
                                type="checkbox"
                                name="taakNodig"
                                value={formValues.taak}
                                onChange={handleCheck}
                                checked={formValues.taak === 1 ? true : false}
                            />
                        </>
                        : null}

                    {formValues.taak === 1 ?
                        <div>
                            <Button onClick={editTaskTimes}>Wijzig taken & tijden</Button>
                        </div>
                        :
                        null
                        // <>@@
                        //     <label className="taakverdelingItemNormal">Welke taken?</label>
                        //     <div className="taakentijd">
                        //         {TakenList}
                        //     </div>
                        //     <br /><br />
                        //     <label className="taakverdelingItemNormal">Welke tijden?</label>
                        //     <div className="taakentijd">
                        //         {TijdenList}
                        //     </div>
                        // </>
                        // : null
                    }

                    <label className="taakverdelingItemNormal"></label>
                    <input type="submit" value="Wijzig de agenda" />

                </form>
            </div>
        </>
    )
}

export default EditEvent
