import { useState, useEffect } from "react";
import "../css/App.css";
import api from "../api/users";

const AddMember = (props) => {
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isTaakverdeling, setIsTaakverdeling] = useState(false);
    const [isSecretaris, setIsSecretaris] = useState(false);
    const [isBestuur, setIsBestuur] = useState(false);
    const initialValues = { vnaam: "", anaam: "", email: "", admin: 0, taakverdeling: 0, secretaris: 0, bestuur: 0 };
    const [formValues, setFormValues] = useState(initialValues);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [rechten, setRechten] = useState([]);

    const fetchRights = async () => {
        const response = await api.get("/users/fetchrights")

        if (response.data !== undefined) {
            setRechten(response.data);
        }
    }

    useEffect(() => {
        if (rechten.length === 0) {
            fetchRights();
        }
    });

    const [checkedState, setCheckedState] = useState(
        new Array(rechten.length).fill(false)
    );

    const handleCloseTask = () => {
        props.setClicked(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true)
    }

    const addUser = async () => {
        setSuccessMessage("");

        if (isSubmit) {
            const apiAdd = await api.post("/users/adduser", { details: formValues, currentUserId: props.userDetails.id, currentUserEmail: props.userDetails.email })

            if (apiAdd.data > 0) {
                setFormErrors({});
                setFormValues(initialValues);
                setIsSubmit(false);
                setSuccessMessage("Lid is toegevoegd!");

                setTimeout(() => {
                    props.setClicked(false);
                }, 2000);

            } else if (apiAdd.data.error === "Email adres bestaat al") {
                setErrorMessage("Email adres is al in gebruik");
                setIsSubmit(false);
            } else {
                setErrorMessage("Er is iets foutgegaan, probeer het opnieuw")
                setIsSubmit(false);
            }
        }
    }

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            addUser();
            setCheckedState(new Array(rechten.length).fill(false))
        }
        // eslint-disable-next-line
    }, [formErrors])

    const validate = (values) => {
        const errors = {};

        if (!values.vnaam || !values.anaam || !values.email) {
            errors.error = "Velden met een * zijn verplicht!";
        }

        return errors;
    }

    const handleCheck = (position) => {

        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);

        let id = position + 1;
        switch (id) {
            case 1:
                if (isAdmin === false) {
                    setFormValues({ ...formValues, admin: 1 });
                    setIsAdmin(true);
                } else {
                    setFormValues({ ...formValues, admin: 0 });
                    setIsAdmin(false);
                }
                break;
            case 2:
                if (isTaakverdeling === false) {
                    setFormValues({ ...formValues, taakverdeling: 1 });
                    setIsTaakverdeling(true);
                } else {
                    setFormValues({ ...formValues, taakverdeling: 0 });
                    setIsTaakverdeling(false);
                }
                break;
            case 3:
                if (isSecretaris === false) {
                    setFormValues({ ...formValues, secretaris: 1 });
                    setIsSecretaris(true);
                } else {
                    setFormValues({ ...formValues, secretaris: 0 });
                    setIsSecretaris(false);
                }
                break;
            case 4:
                if (isBestuur === false) {
                    setFormValues({ ...formValues, bestuur: 1 });
                    setIsBestuur(true);
                } else {
                    setFormValues({ ...formValues, bestuur: 0 });
                    setIsBestuur(false);
                }
                break;
            default:
                break;
        }
    };

    const checkList = rechten.map((({ recht, id }, index) => {
        return (
            <li className="checkbox" key={index}>
                <input
                    type="checkbox"
                    id={`custom-checkbox-${id}`}
                    name={recht}
                    value={index}
                    checked={checkedState[index]}
                    onChange={() => handleCheck(index)}
                />
                <label htmlFor={`custom-checkbox-${index}`}>&nbsp;{recht}</label>
            </li>
        );
    }))

    return (
        <>
            <div className='taakverdelingItem'>
                <div className='taakverdelingItemToggle'>
                    <button onClick={handleCloseTask}>X</button>
                </div>
                <div className='taakverdelingItemHeader'>
                    <h1>Voeg een nieuw lid toe</h1>
                </div>
                <div className='error'>{formErrors ? formErrors.error : null}</div>
                <div className='error'>{errorMessage ? errorMessage : null}</div>
                <div className='success'>{successMessage ? successMessage : null}</div>

                <form onSubmit={handleSubmit}>
                    <label className="taakverdelingItemNormal">* Voornaam:</label>
                    <input
                        type="text"
                        name="vnaam"
                        value={formValues.vnaam}
                        placeholder="Voornaam van lid *"
                        onChange={handleChange}
                    />

                    <label className="taakverdelingItemNormal">* Achternaam:</label>
                    <input
                        type="text"
                        name="anaam"
                        value={formValues.anaam}
                        placeholder="Achternaam van lid *"
                        onChange={handleChange}
                    />

                    <label className="taakverdelingItemNormal">* E-mail:</label>
                    <input
                        type="email"
                        name="email"
                        value={formValues.email}
                        placeholder="E-mail adres van lid *"
                        onChange={handleChange}
                    />

                    <label className="taakverdelingItemNormal">Welke rol heeft dit lid?</label>
                    {checkList}

                    <label className="taakverdelingItemNormal"></label>
                    <input type="submit" value="Voeg lid toe" />

                </form>
            </div>

        </>
    )
}

export default AddMember
