import { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import nl from 'date-fns/locale/nl';
import { format } from "date-fns";
import api from "../api/users";
import { isAdmin, isTaakverdeling } from './check/RightsCheck';
import AgendaTaskTimesModal from './modal/AgendaTaskTimesModal';
import AgendaTimesModal from './modal/AgendaTimesModal';
import styled from 'styled-components';

registerLocale('nl', nl)

const Button = styled.button`
    padding: 0.3rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border: 0.15rem solid #d90000;
    background-color: transparent;
    border-radius: 0.25rem;
    &:hover {
        border: 0.15rem solid #d90000;
        background-color: #d90000;
    }
`

const EventAdmin = (props) => {
    let date = new Date();
    let today = format(date, "yyyy-MM-dd");
    const [takenLijst, setTakenLijst] = useState([]);
    const [tijdenLijst, setTijdenLijst] = useState([]);
    const [showTaskTimes, setShowTaskTimes] = useState(false);
    const [showTimes, setShowTimes] = useState(false);
    const [taskId, setTaskId] = useState();

    const initialValues = { title: "", actType: 0, start: today, end: today, startTijd: "", endTijd: "", description: "", taak: 0, email: 0, taken: { tijden: {} } };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [success, setSuccess] = useState("");
    const [startDate, setStartDate] = useState(date);
    const [endDate, setEndDate] = useState(date);
    const [activityVisible, setActivityVisible] = useState(false);
    const [typeActiviteit, setTypeActiviteit] = useState({})

    const getTaken = async () => {
        if (takenLijst.length === 0) {
            const takenApi = await api.get("/taken/taken");
            setTakenLijst(takenApi.data);

            const tijdenApi = await api.get("/taken/tijden");
            setTijdenLijst(tijdenApi.data);

            const addCheckedTijden = Object.values(tijdenApi.data).map((tijd) => {
                return { ...tijd, checked: false }
            })
            const addCheckedTaken = Object.values(takenApi.data).map((taak) => {
                return { ...taak, checked: false, tijden: addCheckedTijden }
            })

            setFormValues({ ...formValues, taken: addCheckedTaken });
        }
    }

    const getTijden = async () => {
        if (tijdenLijst.length === 0) {
            const tijdenApi = await api.get("/taken/tijden");
            setTijdenLijst(tijdenApi.data);

            const addCheckedTijden = Object.values(tijdenApi.data).map((tijd) => {
                return { ...tijd, checked: false }
            })

            setFormValues({ ...formValues, tijden: addCheckedTijden });
        }
    }

    const getActivities = async () => {
        if (Object.keys(typeActiviteit).length === 0) {
            const apiTypes = await api.get("/agenda/type");
            setTypeActiviteit(apiTypes.data);
        }
    }

    useEffect(() => {
        getActivities();
        getTaken();
        getTijden();
    })

    const activityType = Object.values(typeActiviteit).map((activity) => {
        return (
            <option key={activity.id} style={{ background: activity.color }} value={activity.id}>{activity.type}</option>
        )
    });

    const handleCheck = (e) => {
        props.setSuccess("");
        props.setFail("");
        if (e.target.checked) {
            setFormValues({ ...formValues, "taak": 1 })
        } else {
            setFormValues({ ...formValues, "taak": 0 })
        }
    }

    const handleEmailCheck = (e) => {
        props.setSuccess("");
        props.setFail("");
        if (e.target.checked) {
            setFormValues({ ...formValues, "email": 1 })
        } else {
            setFormValues({ ...formValues, "email": 0 })
        }
    }

    const handleCloseTask = () => {
        props.setClicked(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setSuccess("");
    }

    const onStartDateChange = (date) => {
        let formattedDate = new Date(date)
        setStartDate(formattedDate);
        setEndDate(formattedDate);
        formattedDate = format(formattedDate, "yyyy-MM-dd")

        setFormValues({ ...formValues, "start": formattedDate, "end": formattedDate })
        setFormErrors({});
    };

    const onEndDateChange = (date) => {
        let formattedEndDate = new Date(date)
        setEndDate(formattedEndDate);
        formattedEndDate = format(formattedEndDate, "yyyy-MM-dd")

        setFormValues({ ...formValues, "end": formattedEndDate })
        setFormErrors({});
    };

    const handleSelectChange = (e) => {
        setActivityVisible(true);
        setFormValues({ ...formValues, "actType": parseInt(e.target.value) });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setFormErrors(validate(formValues));
        setIsSubmit(true)
    }

    const addAgenda = async () => {
        const apiAdd = await api.post("/agenda/add", { data: formValues, currentUserId: props.userDetails.id, currentUserEmail: props.userDetails.email })
        if (apiAdd.data !== 0 && isSubmit) {
            props.setEventDetails({ ...props.eventDetails, formValues })
        }
    }

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            addAgenda();
            setSuccess("Het agendapunt is toegevoegd!")
            window.scrollTo(0, 0)

            setStartDate(new Date());
            setEndDate(new Date());
            setFormErrors({});
            setFormValues(initialValues);
            setActivityVisible(false);
            setIsSubmit(false)
        }
        // eslint-disable-next-line
    }, [formErrors])

    const validate = (values) => {
        const errors = {};
        if (!values.title || values.actType === 0 || !values.start || !values.startTijd) {
            errors.error = "Velden met een * zijn verplicht!";
        }

        if (values.startTijd.length <= 4 && values.startTijd.indexOf(":") < 1) {
            errors.error = "Voer aub de tijd correct in, bijvoorbeeld: 20:00"
        }

        if (values.endTijd.length > 0 && values.endTijd.indexOf(":") < 1) {
            errors.error = "Voer aub de tijd correct in, bijvoorbeeld: 21:00"
        }

        if (!values.endTijd) {
            let result = values.startTijd;

            if (errors.error > 0) {
                result = "";
                setFormValues({ ...formValues, "endTijd": result })
            }

            if (result.indexOf(':') !== -1) {
                let eind = values.startTijd.split(':')[0];
                let afterColon = values.startTijd.split(":")[1];

                if (eind < 21) {
                    eind = parseInt(eind) + 3;
                }
                else if (eind < 22) {
                    eind = parseInt(eind) + 2;
                }
                else if (eind < 23) {
                    eind = parseInt(eind) + 1;
                }

                result = eind.toString() + ":" + afterColon;
                setFormValues({ ...formValues, "endTijd": result })
            }
        }


        return errors;
    }

    const editTaskTimes = (e) => {
        e.preventDefault();

        setShowTaskTimes(true);
    }

    return (
        <>
            {showTaskTimes ? <AgendaTaskTimesModal setTaskId={setTaskId} show={showTaskTimes} setShow={setShowTaskTimes} setShowTimes={setShowTimes} formValues={formValues} setFormValues={setFormValues} /> : null}
            {showTimes ? <AgendaTimesModal taskId={taskId} show={showTimes} setShow={setShowTimes} setShowTask={setShowTaskTimes} formValues={formValues} setFormValues={setFormValues} /> : null}

            <div className='taakverdelingItem'>
                <div className='taakverdelingItemToggle'>
                    <button onClick={handleCloseTask}>X</button>
                </div>
                <div className='taakverdelingItemHeader'>
                    <h1>Voeg een nieuw agendapunt toe</h1>
                </div>
                <div className="inform">Indien geen eindtijd aangegeven, word deze automatisch bepaald aan de hand van de starttijd</div>
                <div className='error'>{formErrors ? formErrors.error : null}</div>
                <div className='success'>{success ? success : null}</div>
                <form onSubmit={handleSubmit}>
                    <label className={Object.keys(formErrors).length > 0 ? "taakverdelingItemReq" : "taakverdelingItemNormal"}>* Agenda punt</label>
                    <input
                        type="text"
                        name="title"
                        value={formValues.title}
                        placeholder="Naam van activiteit *"
                        onChange={handleChange}
                    />

                    <label className={Object.keys(formErrors).length > 0 ? "taakverdelingItemReq" : "taakverdelingItemNormal"}>* Soort activiteit</label>
                    <select
                        value={formValues.actType}
                        name="actType"
                        onChange={handleSelectChange}>
                        <option disabled={activityVisible}>
                            Soort activiteit
                        </option>
                        {activityType}
                    </select>

                    <label className={Object.keys(formErrors).length > 0 ? "taakverdelingItemReq" : "taakverdelingItemNormal"}>* Start datum</label>
                    <DatePicker
                        locale="nl"
                        dateFormat="yyyy-MM-dd"
                        selected={startDate}
                        onChange={onStartDateChange}
                        showWeekNumbers
                        disabledKeyboardNavigation
                        name="startDate"
                    />

                    <label className="taakverdelingItemNormal">* Eind datum</label>
                    <DatePicker
                        locale="nl"
                        dateFormat="yyyy-MM-dd"
                        selected={endDate}
                        onChange={onEndDateChange}
                        showWeekNumbers
                        disabledKeyboardNavigation
                        name="endDate"
                    />

                    <label className={Object.keys(formErrors).length > 0 ? "taakverdelingItemReq" : "taakverdelingItemNormal"}>* Begin tijd   </label>
                    <input
                        type="text"
                        name="startTijd"
                        value={formValues.startTijd}
                        placeholder="Begin tijd? bv: 20:00 *"
                        onChange={handleChange}
                    />

                    <label className="taakverdelingItemNormal">Eind tijd</label>
                    <input
                        type="text"
                        name="endTijd"
                        value={formValues.endTijd}
                        placeholder="Eind tijd? bv: 23:00"
                        onChange={handleChange}
                    />

                    <label className="taakverdelingItemNormal">Omschrijving</label>
                    <textarea
                        type="text"
                        name="description"
                        value={formValues.description}
                        placeholder="Voeg eventueel een omschrijving / begeleidende tekst toe"
                        onChange={handleChange}
                    />

                    {isAdmin() || isTaakverdeling() ? <><label className="taakverdelingItemNormal">Taken nodig?</label>
                        <input
                            type="checkbox"
                            name="taakNodig"
                            value={formValues.taak}
                            onChange={handleCheck}
                            checked={formValues.taak === 1 ? true : false}
                        />
                    </>
                        : null}

                    {formValues.taak === 1 ?
                        <div>
                            <Button onClick={editTaskTimes} style={{}}>Wijzig taken & tijden</Button>
                        </div>
                        :
                        null
                    }

                    {isAdmin() || isTaakverdeling() ? <><label className="taakverdelingItemNormal">Email versturen?</label>
                        <input
                            type="checkbox"
                            name="email"
                            value={formValues.email}
                            onChange={handleEmailCheck}
                            checked={formValues.email === 1 ? true : false}
                        />
                    </>
                        : null}

                    <label className="taakverdelingItemNormal"></label>
                    <input type="submit" value="Zet in agenda" />
                </form>
            </div>
        </>
    )
}

export default EventAdmin
