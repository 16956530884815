import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid';
import { Modal } from "react-bootstrap";
import styled from 'styled-components';
import api from "../api/users";

const Item = styled.div`
    background-color: rgba(0, 0, 0, 0.1) !important;
    font-size: 1rem !important;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
    white-space: pre-line;
    overflow: auto;
    max-height: 100px;
`;

function BodyList({ body, eventId, addPersonHandler, handleDelete, handleIndelen }) {
    const [newName, setNewName] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [eventData, setEventData] = useState({})
    const [timesPerTask, setTimesPerTask] = useState([]);

    useEffect(() => {
        const GetTimesPerTask = async () => {
            const apiResponse = await api.get("agenda/tijdenbijtaak/" + eventId);
            setTimesPerTask(apiResponse.data);
        }

        if (eventId > 0) {
            GetTimesPerTask();
        }
    }, [eventId])

    if (body.length === 0 || !body[0]) {
        return null;
    }

    const length = body[0].length;
    const tijdLength = body[1].length;
    const ingeschreven = body[2];

    const openModal = (data) => {
        setShowModal(true);
        setEventData(data);
    }

    const addPerson = () => {
        addPersonHandler(eventData, newName)

        setEventData({});
        setNewName("");
        setShowModal(false);
    }

    const handleModalClose = () => {
        setShowModal(false);
    }

    // const tijden = body[1]
    //tijden.sort((a, b) => (a.id > b.id) ? 1 : -1);

    const showResults = () => {
        let result = [];

        for (let tijd = 0; tijd < tijdLength; tijd++) {
            let namenBijTijd = ingeschreven.filter(result => parseInt(result.tijd_id) === body[1].filter(filt => filt.index === tijd)[0].id);
            let showTijd = body[1][tijd].tijd;

            for (let taak = 0; taak < length; taak++) {

                let namen = namenBijTijd.filter(result => result.taak_id === body[0].filter(filt => filt.index === taak)[0].id);
                if (taak === 0) {
                    result.push(
                        <Item key={uuid()}>
                            {showTijd}
                        </Item>
                    );
                    continue;
                }

                let showNaam = null

                if (handleIndelen && handleDelete) {
                    showNaam = namen.map(naam => {
                        return (
                            <div key={uuid()} id={naam.id} style={{ textAlign: "left", paddingLeft: "0.5rem" }}>
                                {
                                    naam.ingedeeld === true ?
                                        <span className="delete">
                                            <button title={naam.taak_id} id={naam.id} onClick={handleDelete}>{naam.naam}</button>
                                            <i>{naam.opmerking}</i>
                                        </span>
                                        :
                                        <span className="indelen">
                                            <button title={naam.taak_id} id={naam.id} onClick={handleIndelen}>{naam.naam}</button>
                                            <i>{naam.opmerking}</i>
                                        </span>
                                }
                            </div>
                        )
                    })
                } else {
                    showNaam = namen.map(naam => {
                        return naam.naam + "\n";
                    })
                }

                let taakId = parseInt(body[0].filter(filt => filt.index === taak)[0].id);
                let tijdId = parseInt(body[1].filter(filt => filt.index === tijd)[0].id);

                result.push(
                    <Item key={uuid()}>
                        {showNaam}

                        {
                            addPersonHandler === undefined ? null :
                                timesPerTask.filter(filt => parseInt(filt.taken_id) === taakId && parseInt(filt.tijden_id) === tijdId).length > 0 ?
                                    <div className='addPerson'>
                                        <button onClick={() => openModal({ eventId: eventId, time: body[1].filter(filt => filt.index === tijd)[0].id, task: body[0].filter(filt => filt.index === taak)[0].id })} >+</button>
                                    </div>
                                    : null
                        }
                    </Item>);
            }
        }

        return result;
    }

    return (
        <>
            {
                addPersonHandler === undefined ? null :
                    <Modal show={showModal} onHide={handleModalClose}>
                        <Modal.Header>
                            <Modal.Title>Naam toevoegen</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label>Naam:</label> &nbsp;
                            <input
                                type="text"
                                name="naam"
                                value={newName}
                                placeholder="Voeg toe aan taak"
                                onChange={(e) => setNewName(e.target.value)}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <button variant="primary" onClick={() => addPerson()}>Opslaan</button>
                            <button variant="primary" onClick={() => setShowModal(false)}>Sluiten</button>
                        </Modal.Footer>
                    </Modal>
            }

            {showResults()}
        </>
    )
}

export default BodyList
