import "../css/Panel.css";
import api from "../api/users";
import { useState, useEffect } from "react";
import SettingModal from "./modal/SettingModal";
import ActivityRoles from "./modal/ActivityRoles";

const Settings = (props) => {
    const [maxTaskSubscription, setMaxTaskSubscription] = useState(null);
    const [maxSubscriptionsLoaded, setMaxSubscriptionsLoaded] = useState(false);
    const [handleTaskSub, setHandleTaskSub] = useState(false);
    const [websiteEmail, setWebsiteEmail] = useState("");
    const [showTask, setShowTask] = useState(false);
    const [showTime, setShowTime] = useState(false);
    const [showActivity, setShowActivity] = useState(false);
    const [showRoles, setShowRoles] = useState(false);
    const [showChange, setShowChange] = useState(false);
    const [numberChange, setNumberChange] = useState(false);
    const [taskid, setTaskId] = useState(null);

    const handleNumberChange = async (e) => {
        setNumberChange(true);
        setMaxTaskSubscription(e.target.value);
    }

    const handeleWebsiteEmailChange = async (e) => {
        setWebsiteEmail(e.target.value);
    }

    useEffect(() => {
        if (maxSubscriptionsLoaded && !handleTaskSub && maxTaskSubscription !== null && numberChange) {
            api.post("/settings/maxtasksubscription", { amount: maxTaskSubscription });
            setHandleTaskSub(true);
        }

        setHandleTaskSub(false);
    }, [maxSubscriptionsLoaded, maxTaskSubscription, handleTaskSub, numberChange])

    useEffect(() => {
        const getMaxSubscription = async () => {
            if (maxTaskSubscription === null) {
                const apiMax = await api.get("/settings/maxtasksubscription")
                setMaxTaskSubscription(apiMax.data)
                setMaxSubscriptionsLoaded(true);
            }
        }

        const getWebsiteEmail = async () => {
            if (websiteEmail === "") {
                const apiEmail = await api.get("/settings/websiteemail")
                setWebsiteEmail(apiEmail.data)
            }
        }

        getMaxSubscription();
        getWebsiteEmail();
        // eslint-disable-next-line
    })

    const handleChange = (id) => {
        setTaskId(id);

        setShowChange(true);
        setShowRoles(false);
    }

    return (
        <>
            {showTask ? <SettingModal show={showTask} setShow={setShowTask} type="taken" /> : null}
            {showTime ? <SettingModal show={showTime} setShow={setShowTime} type="tijden" /> : null}
            {showActivity ? <SettingModal show={showActivity} setShow={setShowActivity} type="activiteit" /> : null}
            {showRoles ? <SettingModal show={showRoles} setShow={setShowRoles} handleChange={handleChange} type="rollen" /> : null}
            {showChange ? <ActivityRoles taskid={taskid} show={showChange} setShow={setShowChange} setShowRoles={setShowRoles} /> : null}

            <div className='panelItem'>
                <div className='panelItemToggle'>
                    <button onClick={() => props.setSettingClick(false)}>X</button>
                </div>
                <div className='panelItemHeader'>
                    <h1>Instellingen</h1>
                </div>

                <div className="container">
                    <div className="row" style={{ "marginBottom": "0.25rem" }}>
                        <div className="col" style={{ "textAlign": "right" }}>
                            Email
                        </div>
                        <div className="col" style={{ "textAlign": "left" }}>
                            <input
                                className="settingsInput"
                                type="email"
                                defaultValue={websiteEmail}
                                onKeyDown={() => false}
                                onChange={handeleWebsiteEmailChange}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ "marginBottom": "1rem" }}>
                        <div className="col" style={{ "textAlign": "right" }}>
                            Hoeveel leden mogen zich inschrijven op een taak?
                        </div>
                        <div className="col" style={{ "textAlign": "left" }}>
                            <input
                                className="settingsInput"
                                type="number"
                                defaultValue={maxTaskSubscription}
                                onKeyDown={() => false}
                                onChange={handleNumberChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button className='panelButton' onClick={() => setShowTask(true)}>Taken beheren</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button className='panelButton' onClick={() => setShowTime(true)}>Tijden beheren</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button className='panelButton' onClick={() => setShowActivity(true)}>Activiteiten beheren</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button className='panelButton' onClick={() => setShowRoles(true)}>Rollen beheren</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Settings
