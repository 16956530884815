import secureLocalStorage from "react-secure-storage";

const ExpiredCheck = () => {
    const now = new Date();
    let expired = JSON.parse(secureLocalStorage.getItem("expiry"));

    if (!expired) {
        secureLocalStorage.clear();
        window.location.reload();
    }

    if (now > expired) {
        secureLocalStorage.clear();
        window.location.reload();
    }
}

export default ExpiredCheck;