import TaakCardItem from './TaakCardItem'
import "../css/Cards.css";

const TaakCards = ({ active, setActive, data, setClickedCalendar, setTitle, setEventId }) => {
  const showTitle = "Kies een activiteit:"

  const events = data.map(events => {
    return (events)
  });

  const showCardsClickable = events.map(data => {
    return (<TaakCardItem
      active={false}
      setActive={setActive}
      text={data.title}
      start={data.start}
      startTijd={data.startTijd}
      endTijd={data.endTijd}
      key={data.id}
      id={data.id}
      setClickedCalendar={setClickedCalendar}
      setTitle={setTitle}
      setEventId={setEventId} />
    )
  });

  const showCardsUnclickable = events.map(data => {
    return (<TaakCardItem
      active={true}
      setActive={setActive}
      text={data.title}
      start={data.start}
      startTijd={data.startTijd}
      endTijd={data.endTijd}
      key={data.id}
      id={data.id}
      setClickedCalendar={setClickedCalendar}
      setTitle={setTitle}
      setEventId={setEventId} />
    )
  });

  if (active) {
    return (
      <div className='cards'>
        <h1> {showTitle}</h1>
        <div className='cards__container'>
          <div className='cards__wrappper'>
            <ul className='cards__items'>
              {showCardsUnclickable}
            </ul>
          </div>
        </div>
      </div>
    )
  }
  else {
    return (
      <div className='cards'>
        <h1> {showTitle}</h1>
        <div className='cards__container'>
          <div className='cards__wrappper'>
            <ul className='cards__items'>
              {showCardsClickable}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default TaakCards
