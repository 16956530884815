import React from 'react';

const NotFound = () => (
  <div>
    <h1 className='niet_gevonden'>
      <br />
      Oeps, pagina is niet gevonden!</h1>
    <p className='niet_gevonden'>
      Als je denkt dat dit niet klopt, stuur een berichtje via de <a href="https://www.dedolleinstuivers.nl">website</a><br />
      Vermeld hierin om welke pagina het gaat!
    </p>
  </div >
);

export default NotFound;