import { useState, useEffect } from 'react';
import "../../css/App.css";
import TaakCards from '../TaakCards';
import TaskAdmin from '../TaskAdmin';
import Task from '../Task';
import api from "../../api/users";
import { isAdmin, isTaakverdeling } from '../check/RightsCheck';
import ExpiredCheck from '../check/ExpiredCheck';

const TaakVerdeling = (props) => {
  const [active, setActive] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [clickedCalendar, setClickedCalendar] = useState(false);
  const [title, setTitle] = useState("");
  const [eventDetails, setEventDetails] = useState({});
  const [eventDetailsChecked, setEventDetailsChecked] = useState(false);
  const [eventId, setEventId] = useState(0);

  const getAgenda = async () => {
    if (Object.keys(eventDetails).length === 0 && props.loggedIn && !eventDetailsChecked) {
      try {
        const apiAgendaTaken = await api.get("/agenda/taakverdeling");
        setEventDetails(apiAgendaTaken.data);
        setEventDetailsChecked(true);
      } catch (err) {
        // log the error
      }
    }
  }
  const events = Object.values(eventDetails);

  useEffect(() => {
    getAgenda();
  })

  const vnaam = props.userDetails.voornaam;
  const anaam = props.userDetails.achternaam;
  const userName = vnaam + " " + anaam;
  const userEmail = props.userDetails.email;

  if (isAdmin() || isTaakverdeling()) {
    return (
      <>
        <ExpiredCheck />
        <h1 className='taakverdeling'>TAAKVERDELING</h1>
        <TaakCards
          active={active}
          setActive={setActive}
          data={events}
          setClickedCalendar={setClickedCalendar}
          setTitle={setTitle}
          setEventId={setEventId} />

        {clicked ?
          <TaskAdmin
            event={events}
            setClicked={setClicked}
            userDetails={props.userDetails}
          />
          :
          <button className='adminPanelButton' onClick={() => setClicked(true)}>Open taakverdeling panel</button>
        }

        {clickedCalendar ?
          <Task
            setActive={setActive}
            setClickedCalendar={setClickedCalendar}
            title={title}
            taken={events}
            tijden={events}
            userName={userName}
            userEmail={userEmail}
            eventId={eventId}
            userId={props.userDetails.id}
          />
          : null}
      </>
    )
  }
  return (
    <>
      <ExpiredCheck />
      <h1 className='taakverdeling'>TAAKVERDELING</h1>
      <TaakCards
        active={active}
        setActive={setActive}
        data={events}
        setClickedCalendar={setClickedCalendar}
        setTitle={setTitle}
        setEventId={setEventId} />

      {clickedCalendar ?
        <Task
          setActive={setActive}
          setClickedCalendar={setClickedCalendar}
          title={title}
          taken={events}
          tijden={events}
          userName={userName}
          userEmail={userEmail}
          eventId={eventId}
          userId={props.userDetails.id}
        />
        : null}
    </>
  )
}

export default TaakVerdeling
