import { useState, useEffect } from 'react'
import SignedInOnTask from './SignedInOnTask';
import api from "../api/users";

const TaskAdmin = (props) => {
  const [activity, setActivity] = useState(0);
  const [takenVisible, setTakenVisible] = useState(false);
  const [eventName, setEventName] = useState("");
  const [ingeschreven, setIngeschreven] = useState([]);

  const eventList = props.event.map((events) => {
    return (
      <option key={events.id} value={events.id}>{events.title}</option>
    )
  });

  const handleSelectChange = (e) => {
    setIngeschreven([]);
    setEventName("");
    setActivity(parseInt(e.target.value));
    setTakenVisible(true);
  }

  useEffect(() => {
    const getEventName = async () => {
      if (activity > 0) {
        const apiEvent = await api.get("/taken/eventName/" + activity);

        if (!apiEvent.data.message) {
          setEventName(apiEvent.data);
        }
      }
    }

    getEventName();
    // eslint-disable-next-line
  }, [activity, eventName])

  return (
    <div className='taakverdelingItem' style={{ maxWidth: "90%" }}>
      <div className='taakverdelingItemToggle'>
        <button onClick={() => props.setClicked(false)}>X</button>
      </div>
      <div className='taakverdelingItemHeader'>
        <h1>Taakverdeling Admin Panel</h1>
      </div>

      <div />

      <select onChange={handleSelectChange}>
        <option
          value="select"
          disabled={takenVisible}>
          Kies een activiteit</option>
        {eventList}
      </select>

      <div style={{ visibility: takenVisible ? "visible" : "hidden" }}>
        <div className="grid">
          <h2>Evenement: {eventName}</h2>
        </div>
        <SignedInOnTask eventId={activity} ingeschreven={ingeschreven} setIngeschreven={setIngeschreven} userDetails={props.userDetails} />
      </div>
    </div>
  )
}

export default TaskAdmin