import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import "../../css/Panel.css";
import Ledenlijst from '../Ledenlijst';
import TakenOverzicht from '../TakenOverzicht';
import Settings from '../Settings';
import Logs from '../Logs';
import { anyRight, isAdmin, isBestuur, isSecretaris, isTaakverdeling } from '../check/RightsCheck';
import ExpiredCheck from '../check/ExpiredCheck';

const Panel = (props) => {
    const [role, setRole] = useState("");
    const [userListClick, setUserListClick] = useState(false);
    const [takenListClick, setTakenListClick] = useState(false);
    const [settingClick, setSettingClick] = useState(false);
    const [logClick, setLogClick] = useState(false);
    const [failedMsg, setFailedMsg] = useState("");
    const [succesMsg, setSuccessMsg] = useState("");

    useEffect(() => {
        if (isAdmin()) {
            setRole("Admin");
        } else if (isSecretaris()) {
            setRole("Secretaris");
        } else if (isBestuur()) {
            setRole("Bestuur");
        } else if (isSecretaris()) {
            setRole("Taakverdeling");
        }
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setSuccessMsg("");
            setFailedMsg("");
        }, 5000);
    }, [failedMsg, succesMsg]);

    if (!anyRight()) {
        return "Je hebt geen toegang tot deze pagina";
    }

    return (
        <>
            <ExpiredCheck />
            <div className='panel'>
                <h1>
                    Welkom op het {role} panel!
                </h1>
            </div>

            <div className={failedMsg > "" ? "failedMsg" : succesMsg > "" ? "successMsg" : null}>
                {failedMsg} {succesMsg}
            </div>

            {
                !isTaakverdeling() ?
                    <>
                        {userListClick
                            ?
                            <Ledenlijst role={props.role} setUserListClick={setUserListClick} setFailedMsg={setFailedMsg} setSuccessMsg={setSuccessMsg} userDetails={props.userDetails} />
                            :
                            <button className='panelButton' onClick={() => setUserListClick(true)}>Bekijk ledenlijst</button>
                        }
                    </>
                    :
                    null
            }

            {
                !isSecretaris() ?
                    <>
                        {takenListClick
                            ?
                            <TakenOverzicht role={props.role} setTakenListClick={setTakenListClick} />
                            :
                            <button className='panelButton' onClick={() => setTakenListClick(true)}>Bekijk takenoverzicht</button>
                        }
                        {settingClick
                            ?
                            <Settings role={props.role} setSettingClick={setSettingClick} />
                            :
                            <button className='panelButton' onClick={() => setSettingClick(true)}>Bekijk instellingen</button>
                        }
                    </>
                    :
                    null
            }

            {
                isAdmin() ?
                    <>
                        {logClick
                            ?
                            <Logs setLogClick={setLogClick} />
                            :
                            <button className='panelButton' onClick={() => setLogClick(true)}>Bekijk logs</button>
                        }
                    </>
                    :
                    null
            }
        </>
    )
};

export default Panel;