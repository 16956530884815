import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/Navbar.css';
import { anyRight } from './check/RightsCheck';

const Navbar = (props) => {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  if (parseInt(props.firstLogin) === 1) {
    return (
      <>
        <nav className='navbar'>
          <div className='navbar-container'>
            <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
              De Dolle Instuivers
            </Link>
            <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            </ul>
          </div>
        </nav>
      </>
    );
  }

  if (props.loggedIn) {
    return (
      <>
        <nav className='navbar'>
          <div className='navbar-container'>
            <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
              De Dolle Instuivers
            </Link>
            <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              <li className='nav-item'>
                <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                  Start
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to='/taakverdeling'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Taakverdeling
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to={{ pathname: '/agenda' }}
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  Agenda
                </Link>
              </li>
              {
                anyRight() ?
                  <li className='nav-item'>
                    <Link to='/panel' className='nav-links' onClick={closeMobileMenu}>
                      Panel
                    </Link>
                  </li>
                  :
                  null
              }
              <li className='nav-item'>
                <Link
                  to="/logout"
                  className='nav-links'
                  onClick={closeMobileMenu}>Log uit
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        {button ?? null}
      </>
    );
  }

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            De Dolle Instuivers
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;