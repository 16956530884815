import { useState, useEffect } from 'react'
import api from "../api/users";
import { v4 as uuid } from "uuid";

const Task = (props) => {
  const initialValues = { userId: props.userId, name: props.userName, email: props.userEmail, taak: "select", tijd: "select", opmerking: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [success, setSuccess] = useState("");
  const [failed, setFailed] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [eventTaken, setEventTaken] = useState({});
  const [eventTijden, setEventTijden] = useState({});
  const [taakNummer, setTaakNummer] = useState(0);
  const [added, setAdded] = useState(false);
  const [count, setCount] = useState(0);
  const [maxSubscription, setMaxTaskSubscription] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  }

  const handleChangeTaak = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setTaakNummer(e.target.value);
    setEventTijden({})
  }

  const handleChangeTijd = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  }

  const handleTaskSubmit = (e) => {
    e.preventDefault();
    setSuccess("");
    setFailed("");
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  }

  const handleCloseTask = () => {
    props.setClickedCalendar(prevClicked => !prevClicked);
    props.setActive(false);
  }

  const writeToDatabase = async () => {
    const body = { ...formValues, event: props.eventId };
    const apiInschrijven = await api.post("/taken/inschrijven", body);

    if (apiInschrijven.data === "Success") {
      setAdded(true);
      setSuccess("Je hebt je ingeschreven!");
    } else {
      setFailed(apiInschrijven.data);
    }
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit && added === false) {
      writeToDatabase();

      setTimeout(() => {
        props.setClickedCalendar(false);
        setFormErrors({});
        setSuccess("");
        setFailed("");
        setFormValues(initialValues);
        setIsSubmit(false)
        setAdded(false)
      }, 3000);
    }
    // eslint-disable-next-line
  }, [formErrors])

  const validate = (values) => {
    const errors = {};

    if (!values.name || !values.email || values.taak === "select" || values.tijd === "select") {
      errors.error = "Velden met een * zijn verplicht!";
    }

    return errors;
  }

  const getTaken = async () => {
    if (Object.keys(eventTaken).length === 0 && props.eventId !== 0) {
      const apiTaken = await api.post("/taken/allTaken", { eventId: props.eventId });
      setEventTaken(apiTaken.data);
      if (apiTaken.data === false) {
        setEventTaken([{ taak: "Fout in taken ophalen" }]);
      } else {
        setEventTaken(apiTaken.data);
      }
    }
  }

  const getTijden = async () => {
    if (Object.keys(eventTijden).length === 0 && taakNummer !== 0) {
      const apiTijden = await api.post("/taken/allTijden", { eventId: props.eventId, taakId: taakNummer });

      if (apiTijden.data === false) {
        setEventTijden([{ tijd: "Fout in tijden ophalen" }])
      } else {
        setEventTijden(apiTijden.data);
        getCount();
      }
    }
  }

  useEffect(() => {
    getTaken();
    getTijden();
    getMaxSubscription();
  })


  const taken = Object.values(eventTaken).map((taak) => {
    return (
      <option key={taak.id} value={taak.id}>{taak.taak}</option>
    )
  })

  const getCount = async () => {
    const apiCount = await api.get("/settings/getsignedin/" + props.eventId + "/" + taakNummer)

    setCount(apiCount.data);
  }

  const getMaxSubscription = async () => {
    if (maxSubscription === null) {
      const apiMax = await api.get("/settings/maxtasksubscription");
      setMaxTaskSubscription(apiMax.data)
    }
  }

  const tijden = Object.values(eventTijden).map((tijd) => {
    let filter = Object.values(count).filter(c => c.tijd_id === tijd.id);
    let countNumber = count !== undefined ? filter[0] !== undefined ? filter[0].count : 0 : 0;

    return (
      <option key={uuid()} value={tijd.id}>{tijd.time} ({countNumber} / {maxSubscription})</option>
    )
  })

  return (
    <div className='taakverdelingItem'>
      <div className='taakverdelingItemToggle'>
        <button onClick={handleCloseTask}>X</button>
      </div>
      <div className='taakverdelingItemHeader'>
        <h1>{props.title}</h1>
      </div>
      {formErrors ? <div className='error'>{formErrors.error}</div> : null}
      {success ? <div className='success'>{success}</div> : null}
      {failed ? <div className='error'>{failed}</div> : null}

      <form onSubmit={handleTaskSubmit}>
        <label className={Object.keys(formErrors).length > 0 ? "taakverdelingItemReq" : "taakverdelingItemNormal"}>* Naam</label>
        <input
          type="text"
          name="name"
          value={formValues.name}
          placeholder="Vul hier je naam in *"
          onChange={handleChange} />

        <label className={Object.keys(formErrors).length > 0 ? "taakverdelingItemReq" : "taakverdelingItemNormal"}>* Email</label>
        <input
          type="email"
          name="email"
          value={formValues.email}
          placeholder="Vul hier je email adres in *"
          onChange={handleChange} />

        <label className={Object.keys(formErrors).length > 0 ? "taakverdelingItemReq" : "taakverdelingItemNormal"}>* Taak</label>
        <select
          value={formValues.taak}
          onChange={handleChangeTaak}
          name="taak">
          <option value="select">Kies een taak *</option>
          {taken}
        </select>

        <label className={Object.keys(formErrors).length > 0 ? "taakverdelingItemReq" : "taakverdelingItemNormal"}>* Tijd</label>
        <select
          value={formValues.tijd}
          onChange={handleChangeTijd}
          name="tijd">
          <option value="select">Kies een tijd *</option>
          {tijden}
        </select>

        <label className="taakverdelingItemNormal">Opmerking:</label>
        <textarea
          value={formValues.opmerking}
          placeholder=" "
          onChange={handleChange}
          name="opmerking" />
        <label className="taakverdelingItemNormal"></label>
        <input type="submit" value="Verstuur" />
      </form>
    </div>
  )
}

export default Task