import './css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/pages/Home';
import TaakVerdeling from './components/pages/TaakVerdeling';
import Agenda from './components/pages/Agenda';
import Login from './components/pages/Login';
import NotFound from './components/pages/NotFound';
import useLogin from './components/Hooks/useLogin';
import Logout from './components/pages/Logout';
import useDetails from './components/Hooks/useDetails';
import api from "./api/users";
import { useEffect, useState } from "react";
import Panel from './components/pages/Panel';
import useRights from './components/Hooks/useRights';

function App() {
  const { loggedIn, setLoggedIn } = useLogin();
  const { details, setDetails } = useDetails();
  const { rights, setRights } = useRights();
  const [userInfo, setUserInfo] = useState({});
  const [firstLogin, setFirstLogin] = useState(userInfo.firstLogin);

  const getRights = async () => {
    if (!rights?.error === 400 && userInfo.id > 0) {
      const apiRights = await api.get('/users/getrights/' + userInfo.id);
      setRights(apiRights.data);
    }
  }

  useEffect(() => {
    getRights();
    // eslint-disable-next-line
  }, [userInfo])

  const fetchInfo = async () => {
    const apiInfo = await api.post("/users/info", details);
    setUserInfo(apiInfo.data.details);
  }

  useEffect(() => {
    if (Object.keys(userInfo).length === 0) {
      fetchInfo();
    }
  })
  if (!loggedIn.token) {
    return (
      <Router>
        <Navbar />
        <Login setLoggedIn={setLoggedIn} details={details} setDetails={setDetails} setRights={setRights} userInfo={userInfo} setUserInfo={setUserInfo} setFirstLogin={setFirstLogin} />
      </Router>
    )
  }

  return (
    <Router>
      <Navbar loggedIn={loggedIn} firstLogin={firstLogin} />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/panel" element={<Panel userDetails={userInfo} />} />
        <Route path="/" element={<Home loggedIn={loggedIn} firstLogin={firstLogin} setFirstLogin={setFirstLogin} userDetails={userInfo} />} />
        <Route path="/taakverdeling" element={<TaakVerdeling loggedIn={loggedIn} userDetails={userInfo} />} />
        <Route path="/agenda" element={<Agenda userDetails={userInfo} loggedIn={loggedIn} />} />
        <Route path="/logout" element={<Logout setLoggedIn={setLoggedIn} userDetails={userInfo} setUserInfo={setUserInfo} />} />
      </Routes>
    </Router>
  )
}

export default App;
