import { useState } from "react";
import secureLocalStorage from "react-secure-storage";

const useLogin = () => {
  const getLoggedIn = () => {
    const loggedInToken = JSON.parse(secureLocalStorage.getItem("token"));
    const loggedInExpiry = JSON.parse(secureLocalStorage.getItem("expiry"));

    const userLoggedIn = { "token": loggedInToken, "expiry": loggedInExpiry };
    // console.log(new Date());
    // console.log(new Date(userLoggedIn.expiry));
    return userLoggedIn;
  }

  const [loggedIn, setLoggedIn] = useState(getLoggedIn());

  const saveLoggedIn = (token) => {
    const now = new Date();
    const ttl = 1000 * 60 * 60;
    const expiry = now.getTime() + ttl;

    secureLocalStorage.setItem("token", JSON.stringify(token));
    secureLocalStorage.setItem("expiry", JSON.stringify(expiry));

    const userLoggedIn = { "token": token, "expiry": expiry };

    setLoggedIn(userLoggedIn);
  }

  return {
    setLoggedIn: saveLoggedIn,
    loggedIn
  }
}

export default useLogin