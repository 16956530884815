import { Link } from 'react-router-dom'

const TaakCardItem = (props) => {

  const handleClick = (e) => {
    props.setEventId(e.target.id)
    props.setClickedCalendar(true);
    props.setTitle(e.target.firstChild.textContent);
    props.setActive(true);
  }

  const handleClickFalse = (e) => {
    props.setEventId(e.target.id)
    props.setClickedCalendar(true);
    props.setTitle(e.target.firstChild.textContent);
    props.setActive(true);
  }

  if (props.active) {
    return (
      <>
        <li className='cards__item'>
          <Link className='cards__item__link' to={"#"} onClick={handleClickFalse}>
            <div className='cards__item__info' id={props.id}>
              <div className='cards__item__text' id={props.id}>
                {props.text}
                <br /> <br /><br />
                {props.start}
              </div>
            </div>
          </Link >
        </li >
      </>
    )
  }
  else {
    return (
      <>
        <li className='cards__item'>
          <Link className='cards__item__link' to={"#"} onClick={handleClick}>
            <div className='cards__item__info' id={props.id}>
              <div className='cards__item__text' id={props.id}>
                {props.text}
                <br /> <br /><br />
                {props.start}
              </div>
            </div>
          </Link>
        </li>
      </>
    )
  }
}

export default TaakCardItem
