import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import styled from 'styled-components';
import api from "../../api/users";
import { v4 as uuid } from 'uuid';

const Items = styled.div`
    width: 100%;
    padding: 0.25rem;
`;

const Item = styled.div`
    width: 100%;
    padding: 0.25rem;
    border: 0.10rem solid #000;
    border-radius: 0.15rem;
    background-color: #F8F8F8;
`;

const NameWider = styled.div`
    display: inline-block;
    width: 90%;
    padding: 0.25rem;
`;

const Delete = styled.div`
    display: inline-block;
    width: 10%;
`;

const Add = styled.div`
    display: inline-block;
    margin-top: 0.5rem;
    padding-left: 1rem;
`;

const Button = styled.button`
    display: inline-block;
    border: 0.1rem solid #D90000;
    border-radius: 0.15rem;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    color: black;
    &:hover {
        background-color: red;
        color: white;
    }
`;

const Input = styled.input`
    display: inline-block;
    padding: 0.25rem;
    width: 60%;
    margin-right: 0.25rem;
`;

const Message = styled.div`
    display: flex;
    margin-left: 2rem;
    margin-top: 0.5rem; 
`;

function ActivityRoles({ taskid, show, setShow, setShowRoles }) {
    const [itemsFetched, setItemsFetched] = useState(false);
    const [items, setItems] = useState([]);
    const [message, setMessage] = useState("");
    const [newItem, setNewItem] = useState("");

    const fetchItems = async () => {
        if (!itemsFetched) {
            const apiGet = await api.get("settings/getitem/roluser/" + parseInt(taskid));

            if (apiGet.data.some(item => item.hasOwnProperty("error"))) {
                setMessage("Ophalen van gegevens is mislukt");
            } else {
                setItems(apiGet.data);
                setItemsFetched(true);
            }
        }
    }

    useEffect(() => {
        fetchItems();
    })

    const handleDelete = async (id) => {
        let apiDelete = await api.delete("settings/deleteitem/roluser/" + id);

        if (apiDelete.data) {
            setItems(items.filter(i => i.id !== id));
            setMessage("Succesvol verwijderd!");

            setTimeout(() => {
                setMessage("");
            }, 3000);
        } else {
            setMessage("Er is iets misgegaan, probeer het opnieuw...");
        }
    }

    const handleAdd = async (name) => {
        const apiPost = await api.post("settings/additem", { type: "roluser", taskid: taskid, name: name });

        if (apiPost.data.length !== 0) {
            setItems([...items, { id: apiPost.data.id, taakrol: taskid, name: apiPost.data.name }])

            setMessage("Succesvol toegevoegd!");

            setTimeout(() => {
                setMessage("");
            }, 3000);
        } else {
            setMessage("Er is iets misgegaan, probeer het opnieuw...");
        }
    }

    return (
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Invulling rollen bij activiteit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Items>
                    {items.map(item => {
                        return (
                            <Item key={item.id}>
                                <NameWider>
                                    {item.name}
                                </NameWider>
                                <Delete>
                                    <Button onClick={() => handleDelete(item.id)}>X</Button>
                                </Delete>
                            </Item>
                        )
                    })}
                </Items>

                {message === "" ? null : <Message style={{}}>{message}</Message>}

                <Add key={uuid}>
                    <Input placeholder="Naam van item" value={newItem} onChange={(e) => setNewItem(e.target.value)} />
                    <Button onClick={() => handleAdd(newItem) & setNewItem("")} disabled={newItem === "" ? true : false}>Voeg toe</Button>
                </Add>
            </Modal.Body>
            <Modal.Footer>
                <button variant="primary" onClick={() => setShow(false) & setShowRoles(true)}>Sluiten</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ActivityRoles
