import React, { useState } from 'react'
import api from "../api/users";
import { useEffect } from 'react';

function Attendees({ eventId, attending }) {
    const [attendees, setAttendees] = useState([]);

    const last = attendees.length - 1


    useEffect(() => {
        const fetchAttendees = async () => {
            const apiAttendees = await api.get(`agenda/attendees/${eventId}`)
            setAttendees(apiAttendees.data);
        }

        fetchAttendees();
    }, [eventId, attending])

    const attendeeList =
        attendees.map((attendee, index) => {
            if (index === last) {
                return attendee
            } else {
                return attendee + ", "
            }
        })


    if (attendees.length > 0) {
        return (
            <>
                <h5>Aanwezigen: </h5>
                <div className='attendees'>
                    {attendeeList}
                </div>
            </>
        )
    } else {
        return null;
    }
}

export default Attendees
