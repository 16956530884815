import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import api from "../../api/users";
import ListToManage from '../list/ListToManage';

function SettingModal({ type, show, setShow, handleChange, setShowRoles }) {
    const [current, setCurrent] = useState([]);
    const [message, setMessage] = useState("");

    const fetchTasks = async () => {
        const apiGet = await api.get("settings/getitem/" + type);

        if (apiGet.data.some(item => item.hasOwnProperty('error'))) {
            setMessage("Ophalen van " + type + " is mislukt");
        } else {
            setCurrent(apiGet.data);
        }
    }

    const Message = (text) => {
        setMessage(text);

        setTimeout(() => {
            setMessage("");
        }, 3000);
    }

    const handleAdd = async (name) => {
        const apiPost = await api.post("settings/additem", { type: type, name: name });

        if (apiPost.data.length !== 0) {
            setCurrent([...current, { id: apiPost.data.id, name: apiPost.data.name }])

            Message("Succesvol toegevoegd!");
        } else {
            Message("Er is iets misgegaan, probeer het opnieuw...");
        }
    }

    const handleDelete = async (id) => {
        let apiDelete = await api.delete("settings/deleteitem/" + type + "/" + id)

        if (apiDelete) {
            Message("Succesvol verwijderd!");

            fetchTasks();
        } else {
            Message("Er is iets misgegaan, probeer het opnieuw...");
        }
    }

    useEffect(() => {
        if (current.length === 0) {
            fetchTasks();
        }
        // eslint-disable-next-line
    })

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>{Capitalize(type)} beheren</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {handleChange === undefined ?
                        <ListToManage items={current} handleAdd={handleAdd} handleDelete={handleDelete} message={message} />
                        :
                        <ListToManage items={current} handleAdd={handleAdd} handleDelete={handleDelete} handleChange={handleChange} message={message} />
                    }
                </Modal.Body>
                <Modal.Footer>
                    {setShowRoles === undefined ?
                        <button variant="primary" onClick={() => setShow(false)}>Sluiten</button>
                        :
                        <button variant="primary" onClick={() => setShow(false) & setShowRoles(true)}>Sluiten</button>
                    }
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default SettingModal
