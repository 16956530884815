import { useState } from 'react';
import "../../css/App.css";
import CardsEvents from '../CardsEvents';
import CardsTaken from '../CardsTaken';
import AddMember from '../AddMember';
import ChangePassword from '../ChangePassword';
import { useEffect } from 'react';
import api from "../../api/users";
import { isAdmin, isBestuur } from '../check/RightsCheck';
import ExpiredCheck from '../check/ExpiredCheck';

const Home = (props) => {
  const [clicked, setClicked] = useState(false);
  const [clickedPW, setClickedPW] = useState(false);
  const [taskDetailsChecked, setTaskDetailsChecked] = useState(false);
  const [agendaMax, setAgendaMax] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const [taskDetails, setTaskDetails] = useState({});

  const getAgenda = async () => {
    if (Object.keys(eventDetails).length === 0 && props.loggedIn && !agendaMax) {
      try {
        const apiAgenda = await api.get("/agenda/max");
        setEventDetails(apiAgenda.data);
        setAgendaMax(true);
      } catch (err) {
        // log the error
      }
    }
  }

  useEffect(() => {
    getAgenda();
  });

  useEffect(() => {
    const getTaken = async () => {
      if (Object.keys(taskDetails).length === 0 && props.userDetails.id && !taskDetailsChecked) {
        try {
          const apiTaken = await api.post("/taken/signedupmax", { userId: props.userDetails.id })
          setTaskDetails(apiTaken.data)
          setTaskDetailsChecked(true);
        } catch (err) {
          // log the error
        }
      }
    }

    getTaken();
    // eslint-disable-next-line
  }, [eventDetails, props.userDetails.id, taskDetails, taskDetailsChecked])

  if (parseInt(props.firstLogin) === 1) {
    return (
      <>
        <div className='home'>
          <h1>Welkom {props.userDetails.voornaam}</h1>
        </div>

        <div className='firstLogin'>
          <h2> Je logt nu voor het eerst in, of je wachtwoord is gereset, wijzig a.u.b. je wachtwoord!</h2>
        </div>

        <ChangePassword setClickedPW={setClickedPW} firstLogin={props.firstLogin} setFirstLogin={props.setFirstLogin} id={props.userDetails.id} />

      </>
    )
  }
  else {
    if (isAdmin() || isBestuur()) {
      return (
        <>
          <ExpiredCheck />

          <div className='home'>
            <h1>Welkom {props.userDetails.voornaam}</h1>
          </div>

          {clicked ? <AddMember setClicked={setClicked} userDetails={props.userDetails} /> : <button className='adminPanelButton' onClick={() => setClicked(true)}>Voeg een nieuw lid toe</button>}

          {clickedPW ? <ChangePassword setClickedPW={setClickedPW} id={props.userDetails.id} firstLogin={props.firstLogin} /> : <button className='adminPanelButton' onClick={() => setClickedPW(true)}>Wijzig je wachtwoord</button>}

          {
            Object.keys(eventDetails).length > 0 ?
              <div className='cards'>
                <h2>De volgende activiteiten:</h2>
                <CardsEvents events={eventDetails} />
              </div>
              :
              <div className='cards'>
                <h2>Er zijn nog geen agenda punten</h2>
              </div>
          }

          {
            Object.keys(taskDetails).length > 0 ?
              <div className='cards'>
                <h2>Jouw volgende taken:</h2>
                <CardsTaken tasks={taskDetails} />
              </div>
              :
              <div className='cards'>
                <h2>Je bent nog niet ingedeeld op taken</h2>
              </div>
          }
        </>
      )
    } else {
      return (
        <>
          <div className='home'>
            <h1>Welkom {props.userDetails.voornaam}</h1>
          </div>

          {clickedPW ? <ChangePassword setClickedPW={setClickedPW} id={props.userDetails.id} firstLogin={props.firstLogin} /> : <button className='adminPanelButton' onClick={() => setClickedPW(true)}>Wijzig je wachtwoord</button>}

          {
            Object.keys(eventDetails).length > 0 ?
              <div className='cards'>
                <h2>De volgende activiteiten:</h2>
                <CardsEvents events={eventDetails} />
              </div>
              :
              <div className='cards'>
                <h2>Er zijn nog geen agenda punten</h2>
              </div>
          }

          {
            Object.keys(taskDetails).length > 0 ?
              <div className='cards'>
                <h2>Jouw volgende taken:</h2>
                <CardsTaken tasks={taskDetails} />
              </div>
              :
              <div className='cards'>
                <h2>Je bent nog niet ingedeeld op taken</h2>
              </div>
          }
        </>
      )
    }
  }
}

export default Home

