import { useState } from "react";
import secureLocalStorage from "react-secure-storage";

const useDetails = () => {
    const getDetails = () => {
        let voornaamString = JSON.parse(secureLocalStorage.getItem("voornaam"));
        let achternaamString = JSON.parse(secureLocalStorage.getItem("achternaam"));
        let emailString = JSON.parse(secureLocalStorage.getItem("email"));

        const userDetails = { "voornaam": voornaamString, "achternaam": achternaamString, "email": emailString };

        return userDetails;
    }

    const [details, setDetails] = useState(getDetails());

    const saveDetails = (userDetails) => {
        secureLocalStorage.setItem("voornaam", JSON.stringify(userDetails.voornaam));
        secureLocalStorage.setItem("achternaam", JSON.stringify(userDetails.achternaam));
        secureLocalStorage.setItem("email", JSON.stringify(userDetails.email));
        setDetails(userDetails);
    }

    return {
        setDetails: saveDetails,
        details
    }
}

export default useDetails