import React from 'react'
import * as XLSX from "xlsx";
import api from "../../api/users";

function CreateExcelFromArray({ fileName, eventId }) {

    const handleExport = async () => {
        const apiExcel = await api.post("/file/excel", { eventId: eventId });

        if (apiExcel.data !== null) {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet(apiExcel.data);

            XLSX.utils.book_append_sheet(wb, ws, "sheet1");

            XLSX.writeFile(wb, fileName + ".xlsx");
        }
    }

    return (
        <button
            className='panelButton'
            onClick={handleExport}
        >
            Exporteer naar Excel
        </button>
    )
}

export default CreateExcelFromArray
