import React from 'react'
import { Modal } from "react-bootstrap";
import api from "../../api/users";
import styled from 'styled-components';

const Field = styled.div`
    // border: 1px solid black;
    width: 50%;
    margin: auto;
`;

const Button = styled.button`
    margin-left: 1rem;
    margin-right: auto;
    width: 40%;
`;

function ConfirmationModal({ deleteId, userId, type, show, setShow, setSuccess, setFail, handleClose, closeUserList }) {

    const handleDelete = async () => {
        const removeApi = await api.delete("/settings/removeagendaoruser/" + deleteId + "/" + type + "/" + userId);
        if (removeApi.data) {
            if (setSuccess !== undefined) {
                setSuccess("Succesvol verwijderd");
            }
            if (closeUserList !== undefined) {
                closeUserList(false);
            }
            handleClose(prevClicked => !prevClicked);
            setShow(false);
        } else {
            if (setFail !== undefined) {
                setFail("Er ging iets mis met het verwijderen, probeer het opnieuw.");
            }
            setShow(false);
        }
    }

    return (
        <>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>Weet je het zeker?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Field>
                        <Button onClick={handleDelete}>Ja</Button>
                        <Button onClick={() => setShow(false)}>Nee</Button>
                    </Field>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default ConfirmationModal
