import { useState } from "react";
import secureLocalStorage from "react-secure-storage";

const useRights = () => {
    const getRights = () => {
        const rightsString = JSON.parse(secureLocalStorage.getItem("overig"));
        return rightsString;
    }

    const [rights, setRights] = useState(getRights());

    const saveRights = (userRights) => {
        secureLocalStorage.setItem("overig", JSON.stringify(userRights));
        setRights(userRights);
    }

    return {
        setRights: saveRights,
        rights
    }
}

export default useRights