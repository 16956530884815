import React, { useState } from 'react'
import Attendees from './Attendees';
import api from "../api/users";
import ConfirmationModal from './modal/ConfirmationModal';
import secureLocalStorage from "react-secure-storage";
import { isAdmin, isSecretaris, isTaakverdeling } from './check/RightsCheck';

const Event = (props) => {
    const [attending, setAttending] = useState(false);
    const [remove, setRemove] = useState(false);

    const handleCloseTask = () => {
        props.setClickedCalendar(prevClicked => !prevClicked);
    }

    const checkDatum = (dayStart, dayEnd) => {

        if (dayStart === dayEnd) {
            return <p>Datum:<br />{dayStart}</p>;
        }
        else {
            return <p>Datum:<br />{dayStart} - {dayEnd}</p>;
        }
    }

    const omschrijving = (omschrijving) => {
        if (omschrijving !== "") {
            return (
                <>
                    <br />
                    <p>Omschrijving: <br />{props.description}</p>
                </>
            )
        }
    }

    const handleEditClick = () => {
        props.setEditClicked(true)
        props.setClickedCalendar(false)
    }

    const checkIfAttending = async () => {
        const apiAttendees = await api.get(`agenda/attendees/${props.eventId}`)

        const vnaam = secureLocalStorage.getItem("voornaam")
        const voornaam = JSON.parse(vnaam);

        const anaam = secureLocalStorage.getItem("achternaam")
        const achternaam = JSON.parse(anaam);

        if (apiAttendees.data.includes(voornaam + " " + achternaam)) {
            setAttending(true);
        } else {
            setAttending(false);
        }
    }

    checkIfAttending();

    const attendingEvent = async () => {
        await api.post("agenda/attending", { event_id: props.eventId, attending: attending, user_id: props.userDetails.id })
    }

    const handleAttendee = async () => {
        await attendingEvent();
        setAttending(prev => !prev)
    }

    return (
        <>
            {remove ? <ConfirmationModal deleteId={props.eventId} userId={props.userDetails.id} type="agenda" show={remove} setShow={setRemove} setSuccess={props.setSuccess} setFail={props.setFail} handleClose={handleCloseTask} /> : null}
            < div className='taakverdelingItem' >
                <div className='taakverdelingItemToggle'>
                    <button onClick={handleCloseTask}>X</button>
                </div>
                <div className='taakverdelingItemHeader'>
                    <h1> {props.title} </h1>
                </div>
                <div>
                    {checkDatum(props.dayStart, props.dayEnd)}
                    <p>Tijd: <br />{props.startTijd} - {props.endTijd}</p>
                    {omschrijving(props.description)}
                </div>

                {
                    props.eventType === "4" || props.eventType === "2" ?
                        <div>
                            {!props.isPast ? !attending ? <button className='adminPanelButton' onClick={handleAttendee}>Meld je aanwezig</button> : <button className='adminPanelButton' onClick={handleAttendee}>Meld je afwezig</button> : null}
                        </div>
                        :
                        null
                }

                {
                    isAdmin() || isSecretaris() || isTaakverdeling() ?
                        <div>
                            {props.setEditClicked ? <button className='adminPanelButton' onClick={handleEditClick}>Wijzig agendapunt</button> : null}
                        </div>
                        :
                        null
                }

                {
                    isAdmin() || isSecretaris() ?
                        <div>
                            <button className='adminPanelButton' onClick={() => setRemove(true)}>Verwijder agendapunt</button>
                        </div>
                        :
                        null
                }

                <div>
                    <Attendees attending={attending} eventId={props.eventId} />
                </div>
            </div >
        </>
    )

}

export default Event
