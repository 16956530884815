import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import api from "../../api/users"
import secureLocalStorage from "react-secure-storage";

const Logout = ({ setLoggedIn, userDetails, setUserInfo }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/", { replace: true })

    return async () => {
      const response = await api.post("/users/token", { userId: userDetails.id })
      if (response.data.token.valid !== false) {
        setLoggedIn("")
        secureLocalStorage.clear();
        setUserInfo({})
      }
      else {
        // todo: call to api to log failure
      }
    }
  })
}

export default Logout