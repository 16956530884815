import { useState } from "react";
import CalendarDDI from '../Calendar';
import Event from "../Event";
import EventAdmin from "../EventAdmin";
import EditEvent from "../EditEvent";
import { useEffect } from "react";
import api from "../../api/users";
import Legenda from "../Legenda";
import { isAdmin, isSecretaris, isTaakverdeling } from "../check/RightsCheck";
import ExpiredCheck from "../check/ExpiredCheck";

const Agenda = (props) => {
  const [clicked, setClicked] = useState(false);
  const [clickedCalendar, setClickedCalendar] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [title, setTitle] = useState("");
  const [eventId, setEventId] = useState();
  const [eventType, setEventType] = useState();
  const [dayStart, setDayStart] = useState("");
  const [dayEnd, setDayEnd] = useState("");
  const [startTijd, setStartTijd] = useState("");
  const [endTijd, setEndTijd] = useState("");
  const [description, setDescription] = useState("");
  const [agendaItems, setAgendaItems] = useState({});
  const [taak, setTaak] = useState(0);
  const [success, setSuccess] = useState("");
  const [fail, setFail] = useState("");
  const [isPast, setIsPast] = useState(false);
  const [takenLijst, setTakenLijst] = useState([]);
  const [tijdenLijst, setTijdenLijst] = useState([]);

  const getAgendaItems = async () => {
    if (Object.keys(agendaItems).length === 0 && props.loggedIn) {
      try {
        const apiAgenda = await api.get("/agenda/all");
        setAgendaItems(apiAgenda.data);
      } catch (err) {
        // log the error
      }
    }
  }

  const getTaken = async () => {
    if (takenLijst.length === 0) {
      const takenApi = await api.get("/taken/taken");
      setTakenLijst(takenApi.data);
    }
  }

  const getTijden = async () => {
    if (tijdenLijst.length === 0) {
      const tijdenApi = await api.get("/taken/tijden");
      setTijdenLijst(tijdenApi.data);
    }
  }

  useEffect(() => {
    getAgendaItems();
    getTaken();
    getTijden();
  })

  useEffect(() => {
    setTimeout(function () {
      setSuccess("");
    }, 5000);
  }, [success]);

  useEffect(() => {
    setTimeout(function () {
      setFail("");
    }, 7500);
  }, [success]);

  const checkIfPast = () => {
    const currentDate = new Date();
    const givenDate = new Date(dayStart);

    setIsPast(givenDate < currentDate);
  };

  useEffect(() => {
    checkIfPast();
    // eslint-disable-next-line
  }, [dayStart])

  const events = Object.values(agendaItems);
  if (isAdmin() || isSecretaris() || isTaakverdeling()) {
    return (
      <>
        <ExpiredCheck />
        <h1 className='agenda'>AGENDA</h1>
        {clicked ?
          <EventAdmin
            setClicked={setClicked}
            eventDetails={agendaItems}
            setEventDetails={setAgendaItems}
            userDetails={props.userDetails}
            setSuccess={setSuccess}
            setFail={setFail}
          />
          : <button className='adminPanelButton' onClick={() => setClicked(true)}>Voeg een nieuw agendapunt toe</button>}

        <div className='success'>{success ? success : null}</div>
        <div className='error'>{fail ? fail : null}</div>

        {clickedCalendar ?
          <Event
            setClickedCalendar={setClickedCalendar}
            setEditClicked={setEditClicked}
            setSuccess={setSuccess}
            setFail={setFail}
            editClicked={editClicked}
            title={title}
            eventId={eventId}
            startTijd={startTijd}
            endTijd={endTijd}
            dayStart={dayStart}
            dayEnd={dayEnd}
            description={description}
            eventType={eventType}
            userDetails={props.userDetails}
            isPast={isPast}
          />
          : null}

        {editClicked ?
          <EditEvent
            setAgendaItems={setAgendaItems}
            setEditClicked={setEditClicked}
            setSuccess={setSuccess}
            setFail={setFail}
            userDetails={props.userDetails}
            title={title}
            eventId={eventId}
            eventType={eventType}
            startTijd={startTijd}
            endTijd={endTijd}
            dayStart={dayStart}
            dayEnd={dayEnd}
            description={description}
            taak={taak}
            takenLijst={takenLijst}
            tijdenLijst={tijdenLijst}
          />
          : null}

        <Legenda />

        <CalendarDDI
          events={events}
          setClickedCalendar={setClickedCalendar}
          setTitle={setTitle}
          setEventId={setEventId}
          setStartTijd={setStartTijd}
          setEndTijd={setEndTijd}
          setDescription={setDescription}
          setDayStart={setDayStart}
          setDayEnd={setDayEnd}
          setEventType={setEventType}
          setTaak={setTaak}
        />

      </>
    )
  }
  else {
    return (
      <>
        <ExpiredCheck />
        <h1 className='agenda'>AGENDA</h1>
        {clickedCalendar ?
          <Event
            setClickedCalendar={setClickedCalendar}
            title={title}
            startTijd={startTijd}
            endTijd={endTijd}
            dayStart={dayStart}
            dayEnd={dayEnd}
            description={description}
            eventType={eventType}
            userDetails={props.userDetails}
            eventId={eventId}
            isPast={isPast}
          />
          : null}

        <Legenda />

        <CalendarDDI
          events={events}
          setClickedCalendar={setClickedCalendar}
          setTitle={setTitle}
          setEventId={setEventId}
          setStartTijd={setStartTijd}
          setEndTijd={setEndTijd}
          setDescription={setDescription}
          setDayStart={setDayStart}
          setDayEnd={setDayEnd}
          setEventType={setEventType}
          setTaak={setTaak}
        />

      </>
    )
  }
}

export default Agenda
