import React, { useState } from 'react'
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

const Items = styled.div`
    width: 100%;
    padding: 0.25rem;
`;

const Item = styled.div`
    width: 100%;
    padding: 0.25rem;
    border: 0.10rem solid #000;
    border-radius: 0.15rem;
    background-color: #F8F8F8;
`;

const Name = styled.div`
    display: inline-block;
    width: 70%;
    padding: 0.25rem;
`;

const NameWider = styled.div`
    display: inline-block;
    width: 90%;
    padding: 0.25rem;
`;

const Delete = styled.div`
    display: inline-block;
    width: 10%;
`;

const Change = styled.div`
    display: inline-block;
    width: 20%;
    padding: 0.25rem;
`;

const Add = styled.div`
    display: inline-block;
    margin-top: 0.5rem;
    padding-left: 1rem;
`;

const Button = styled.button`
    display: inline-block;
    border: 0.1rem solid #D90000;
    border-radius: 0.15rem;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    color: black;
    &:hover {
        background-color: red;
        color: white;
    }
`;

const Input = styled.input`
    display: inline-block;
    padding: 0.25rem;
    width: 60%;
    margin-right: 0.25rem;
`;

const Message = styled.div`
    display: flex;
    margin-left: 2rem;
    margin-top: 0.5rem; 
`;

function ListToManage({ items, handleDelete, handleChange, handleAdd, message }) {
    const [newItem, setNewItem] = useState("");

    return (
        <>
            <Items>

                {items.map(item => {
                    if (handleChange === undefined) {
                        return (
                            <Item key={item.id}>
                                <NameWider>
                                    {item.name}
                                </NameWider>
                                <Delete>
                                    <Button onClick={() => handleDelete(item.id)}>X</Button>
                                </Delete>
                            </Item>
                        )
                    } else {
                        return (
                            <Item key={item.id}>
                                <Name>
                                    {item.name}
                                </Name>
                                <Delete>
                                    <Button onClick={() => handleDelete(item.id)}>X</Button>
                                </Delete>
                                <Change>
                                    <Button onClick={() => handleChange(item.id)}>Wijzig</Button>
                                </Change>
                            </Item>
                        )
                    }
                })}

                {message === "" ? null : <Message style={{}}>{message}</Message>}

                <Add key={uuid}>
                    <Input placeholder="Naam van item" value={newItem} onChange={(e) => setNewItem(e.target.value)} />
                    <Button onClick={() => handleAdd(newItem) & setNewItem("")} disabled={newItem === "" ? true : false}>Voeg toe</Button>
                </Add>

            </Items>
        </>
    )
}

export default ListToManage
