import React from 'react'
import { Link } from 'react-router-dom'

// const CardItem = ( { task, text, path, date, timestart, timeend } ) => {
const CardItem = (props) => {
  return (
    <>
      <li className='cards__item'>
        <Link className='cards__item__link' to="/taakverdeling">
          <div className='cards__item__info'>
            <div className='cards__item__text'>{props.text}</div>
            <div className='cards__item__task'>{props.task}</div>
            <div className='cards__item__task'>{props.name}</div>
            <div className='cards__item__date'><p className='cards__item__header'>Datum: </p> {props.date}</div>
            <div className='cards__item__time'><p className='cards__item__header'>Tijd: </p>{props.time}</div>
          </div>
        </Link>
      </li>
    </>
  )
}

export default CardItem
