import React, { useEffect, useState } from 'react'
import api from "../api/users";
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';

const Item = styled.div`
    background-color: rgba(0, 0, 0, 0.1) !important;
    font-size: 1rem !important;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
    white-space: pre-line;
    overflow: auto;
    max-height: 100px;
`;

function Logs(props) {
    const [logInfo, setLogInfo] = useState({});

    const getLogInfo = async () => {
        const logAPI = await api.get("log/loginfo");

        if (logAPI.data !== null) {
            setLogInfo(logAPI.data);
        }
    }

    useEffect(() => {
        getLogInfo();
    }, [])

    const createGrid = (length) => {
        let css = "auto / 11%"

        for (let i = 1; i < length; i++) {
            css += " 17%"
        }

        return css;
    }

    return (
        <div className='panelItem'>
            <div className='panelItemToggle'>
                <button onClick={() => props.setLogClick(false)}>X</button>
            </div>
            <div className='panelItemHeader'>
                <h1>Log DDI leden portaal</h1>
            </div>

            <div className="panelTakenList">
                <div className="grid-taakverdeling" style={{ grid: createGrid(6) }}>
                    <div>
                        Datum
                    </div>
                    <div>
                        Page
                    </div>
                    <div>
                        Message
                    </div>
                    <div>
                        Stacktrace
                    </div>
                    <div>
                        Aangepast door UserId
                    </div>
                    <div>
                        Email
                    </div>

                    {Object.values(logInfo).map((item) => {
                        return (
                            <React.Fragment key={uuid()}>
                                <Item key={uuid()}>
                                    <div key={uuid()}>
                                        {item.date}
                                    </div>
                                </Item>
                                <Item key={uuid()}>
                                    <div key={uuid()}>
                                        {item.page}
                                    </div>
                                </Item>
                                <Item key={uuid()}>
                                    <div key={uuid()}>
                                        {item.message}
                                    </div>
                                </Item>
                                <Item key={uuid()}>
                                    <div key={uuid()}>
                                        {item.stacktrace}
                                    </div>
                                </Item>
                                <Item key={uuid()}>
                                    <div key={uuid()}>
                                        {item.user_id}
                                    </div>
                                </Item>
                                <Item key={uuid()}>
                                    <div key={uuid()}>
                                        {item.user_name}
                                    </div>
                                </Item>
                            </React.Fragment>
                        )
                    })}
                </div>

            </div>
        </div>
    )
}

export default Logs
