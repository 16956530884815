import React from 'react'
import { Link } from 'react-router-dom'

// const CardItem = ( { task, text, path, date, timestart, timeend } ) => {
const CardItem = (props) => {
  return (
    <>
      <li className='cards__item'>
        <Link className='cards__item__link' to="/agenda">
          <div className='cards__item__info'>
            <div className='cards__item__text'>{props.text}</div>
            <div className='cards__item__date'><p className='cards__item__header'>Datum: </p> {props.date}</div>
            <div className='cards__item__time'><p className='cards__item__header'>Tijd: </p>{props.timestart} : {props.timeend}</div>
          </div>
        </Link>
      </li>
    </>
  )
}

export default CardItem
