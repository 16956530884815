import { useState } from "react";
import "../../css/Login.css";
import api from "../../api/users";
import { Modal, Button } from "react-bootstrap";

function Login({ setLoggedIn, details, setDetails, setRights, setUserInfo, setFirstLogin }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [modal, setModal] = useState(false);
  const [forgotPassword, setForgotPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const loginData = { email: email, pwd: password };
    const response = await api.post('/users/login', loginData)

    if (response.data.valid === false) {
      setErrorMessage("Inlog gegevens onjuist!")
    }
    else {
      setErrorMessage("")
      setDetails(response.data.details);
      setLoggedIn(response.data.token);

      const apiInfo = await api.post("/users/info", response.data.details);
      setUserInfo(apiInfo.data.details);
      setFirstLogin(apiInfo.data.details.firstlogin)

      const apiRights = await api.get('/users/getrights/' + response.data.details.id)
      setRights(apiRights.data)
    }
  };

  const handleEmailChange = (e) => {
    setErrorMessage("")
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setErrorMessage("");
    setPassword(e.target.value)
  }

  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        {errorMessage ? errorMessage : null}
        <div className="input-container">
          <label>E-mail </label>
          <input type="text" value={email} onChange={handleEmailChange} />
        </div>
        <div className="input-container">
          <label>Wachtwoord </label>
          <input type="password" value={password} name="pass" onChange={handlePasswordChange} />
        </div>
        <div className="button-container">
          <input type="submit" value="Log in" />
        </div>
      </form>
    </div>
  );

  const openModal = () => {
    setModal(true);
  }

  const closeModal = () => {
    setForgotPassword("");
    setMessage("");
    setErrorMessage("");
    setModal(false);
  }

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (!forgotPassword) {
      setErrorMessage("Voer je e-mail adres in");
    } else {
      setErrorMessage("");
      const apiForgot = await api.post("/users/forgot", { email: forgotPassword })

      setMessage(apiForgot.data)

      setTimeout(() => {
        closeModal();
      }, 2000);
    }
  }

  return (
    <>
      <Modal show={modal} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title>Wachtwoord vergeten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form">
            <form onSubmit={handleForgotPassword}>
              <div className="error">
                {errorMessage ? errorMessage : null}
              </div>
              <div className="success">
                {message && message}
              </div>
              <div className="input-container">
                <label>E-mail </label>
                <input type="text" value={forgotPassword} onChange={(e) => setForgotPassword(e.target.value)} />
              </div>
              <div className="button-container">
                <input type="submit" value="Vraag nieuw wachtwoord aan" />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>

      <div className="app">
        <div className="login-form">
          <div className="title">Inloggen</div>
          {renderForm}
          <p className="wachtwoordvergeten">
            <button className="panelDeleteButton" onClick={openModal}>Wachtwoord vergeten</button>
          </p>
          <a href="https://www.dedolleinstuivers.nl" className="linkddi">Terug naar www.dedolleinstuivers.nl</a>
        </div>
      </div>
    </>
  );
}

export default Login;