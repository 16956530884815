import { useEffect } from "react";
import api from "../api/users";
import HeaderList from "./HeaderList";
import BodyList from "./BodyList";

const SignedInOnTask = (props) => {

  let headers = [];

  if (props.ingeschreven.length > 0) {
    headers = props.ingeschreven[0];
  }

  const getIngeschreven = async () => {
    if (props.eventId > 0) {
      const apiIngeschreven = await api.get("/taken/getIngeschreven/" + props.eventId)
      props.setIngeschreven(apiIngeschreven.data);
    }
  }

  useEffect(() => {
    getIngeschreven();
    // eslint-disable-next-line
  }, [props.eventId])

  const setFalse = async (id) => {
    await api.post("/taken/false", { id: id, currentUserId: props.userDetails.id, currentUserEmail: props.userDetails.email })
  }

  const setTrue = async (id) => {
    await api.post("/taken/true", { id: id, currentUserId: props.userDetails.id, currentUserEmail: props.userDetails.email })
  }

  const handleIndelen = async (e) => {
    await setTrue(e.target.id);

    props.setIngeschreven({});
    getIngeschreven();
  }

  const handleDelete = async (e) => {
    await setFalse(e.target.id);

    props.setIngeschreven({});
    getIngeschreven();
  }

  const createGrid = (length) => {
    let css = "auto / 11%"

    for (let i = 1; i < length; i++) {
      css += " 17%"
    }

    return css;
  }

  const addPersonHandler = async (eventData, newName) => {
    const data = { ...eventData, userId: props.userDetails.id, userEmail: props.userDetails.email };

    const apiAddPerson = await api.post("/taken/addAttendee", { eventData: data, name: newName })

    if (apiAddPerson.data) {
      props.setIngeschreven({});
      getIngeschreven();
    }
  }

  return (
    <>
      <div className="grid-taakverdeling" style={{ grid: createGrid(props.ingeschreven[0]?.length > 0 ? props.ingeschreven[0]?.length : 6) }}>
        <HeaderList headers={headers} />
        <BodyList body={props.ingeschreven} eventId={props.eventId} handleIndelen={handleIndelen} handleDelete={handleDelete} addPersonHandler={addPersonHandler} />
      </div>
    </>
  )
}

export default SignedInOnTask