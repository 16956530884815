import React, { useState, useEffect } from 'react'
import api from "../api/users";
import { v4 as uuid } from "uuid";

function Legenda() {
    const [legenda, setLegenda] = useState([]);

    const fetchLegenda = async () => {
        const apiLegenda = await api.get("agenda/legenda");

        setLegenda(apiLegenda.data);
    }

    useEffect(() => {
        if (legenda.length === 0) {
            fetchLegenda();
        }
    }, [legenda])

    return (
        <>
            <div className='legendaUitleg'>Wat houden de verschillende kleuren op de agenda in?</div>
            <div className='legenda'>
                {legenda.length > 0 ? legenda.map(leg => {
                    return <div key={uuid()} style={{ background: leg.color }} className='legendaItem'>{leg.type}</div>;
                })
                    : null}
            </div>
        </>
    )
}

export default Legenda