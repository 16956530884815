import React, { useEffect } from 'react'
import { Modal } from "react-bootstrap";
import AgendaTimesCheckbox from '../list/AgendaTimesCheckbox';

function AgendaTimesModal({ show, setShow, setShowTask, formValues, setFormValues, taskId }) {
    useEffect(() => {
        const detectKeyDown = (e) => {
            if (e.keyCode === 27) {
                setShow(false);
                setShowTask(true);
            }
        }

        document.addEventListener('keydown', detectKeyDown);
    })

    const handleClose = () => {
        setShow(false);
        setShowTask(true);
    }

    return (
        <>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>Agenda tijden beheren</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Hier komen de tijden
                    <AgendaTimesCheckbox taskId={taskId} formValues={formValues} setFormValues={setFormValues} />
                </Modal.Body>
                <Modal.Footer>
                    <button variant="primary" onClick={handleClose}>Sluiten</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AgendaTimesModal
