import React from 'react'
import styled from 'styled-components';

const Item = styled.div`
    font-size: 1rem !important;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
    overflow: auto;
    max-height: 100px;
`;


function HeaderList({ headers }) {
    if (headers === undefined) {
        return;
    }

    if (Object.values(headers) === 0) {
        return null;
    }
    return Object.values(headers).map((item) => {
        return (
            <div key={item.index}>
                <Item id={item.id}>
                    {item.taak}
                </Item>
            </div>
        )
    })
}

export default HeaderList
