import React from 'react'
import CardItemTaken from './CardItemTaken'
import "../css/Cards.css";

const Cards = (props) => {
  const tasks = Object.values(props.tasks);
  const showCards = tasks.map(data => {
    return (<CardItemTaken
      text={data.title}
      task={data.task}
      name={data.naam}
      date={data.start}
      time={data.tijd}
      key={data.id} />
    )
  });

  return (
    <div className='cards__container'>
      <div className='cards__wrappper'>
        <ul className='cards__items'>
          {showCards}
        </ul>
      </div>
    </div>
  )
}

export default Cards
