import React, { useEffect } from 'react'
import { Modal } from "react-bootstrap";
import AgendaTaskCheckbox from '../list/AgendaTaskCheckbox';

function AgendaTaskTimesModal({ show, setShow, setShowTimes, formValues, setFormValues, setTaskId }) {

    useEffect(() => {
        const detectKeyDown = (e) => {
            if (e.keyCode === 27) {
                setShow(false);
            }
        }

        document.addEventListener('keydown', detectKeyDown);
    })

    return (
        <>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>Agenda taken & tijden beheren</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Hier komen de taken, met button voor om de tijden te wijzigen
                    <AgendaTaskCheckbox setTaskId={setTaskId} setShow={setShow} setShowTimes={setShowTimes} formValues={formValues} setFormValues={setFormValues} />
                </Modal.Body>
                <Modal.Footer>
                    <button variant="primary" onClick={() => setShow(false)}>Sluiten</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AgendaTaskTimesModal
