import secureLocalStorage from "react-secure-storage";

var rightsString = JSON.parse(secureLocalStorage.getItem("overig"));

function isAdmin() {
    if (!rightsString) {
        return;
    }
    return Boolean(parseInt(rightsString.ddi_admin));
}

function isBestuur() {
    if (!rightsString) {
        return;
    }
    return Boolean(parseInt(rightsString.ddi_bestuur));
}

function isSecretaris() {
    if (!rightsString) {
        return;
    }
    return Boolean(parseInt(rightsString.ddi_secretaris));
}

function isTaakverdeling() {
    if (!rightsString) {
        return;
    }
    return Boolean(parseInt(rightsString.ddi_taakverdeling));
}

function anyRight() {
    if (rightsString === null) {
        rightsString = JSON.parse(secureLocalStorage.getItem("overig"));
    }

    if (isAdmin() || isBestuur() || isSecretaris() || isTaakverdeling()) {
        return true
    } else {
        return false
    }
}

export { isAdmin, isBestuur, isSecretaris, isTaakverdeling, anyRight }
