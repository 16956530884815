import React, { useState } from 'react'
import styled from 'styled-components';

const Items = styled.div`
    width: 100%;
    padding: 0.25rem;
`;

const Item = styled.div`
    width: 100%;
    padding: 0.25rem;
    border: 0.10rem solid #000;
    border-radius: 0.15rem;
    background-color: #F8F8F8;
`;

const Name = styled.div`
    display: inline-block;
    width: 55%;
    padding: 0.25rem;
`;

const Delete = styled.div`
    display: inline-block;
    width: 25%;
`;

const Button = styled.button`
    display: inline-block;
    border: 0.1rem solid #D90000;
    border-radius: 0.15rem;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    color: black;
    &:hover {
        background-color: red;
        color: white;
    }
`;

const ButtonChecked = styled.button`
    display: inline-block;
    border: 0.1rem solid #D90000;
    border-radius: 0.15rem;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    color: black;
    background: #FFCCCB;
    &:hover {
        background-color: red;
        color: white;
    }
`;

function AgendaTimesCheckbox({ taskId, formValues, setFormValues }) {
    const [times, setTimes] = useState(formValues.taken.filter(taak => taak.id === taskId)[0].tijden);

    const handleSelect = (id) => {
        const newTimes = times.map((time) => {
            if (time.id === id) {
                return { ...time, checked: !time.checked };
            } else {
                return { ...time };
            }
        })

        setTimes(newTimes);

        const newTaak = Object.values(formValues.taken).map((taak) => {
            if (taak.id === taskId) {
                return { ...taak, tijden: newTimes }
            } else {
                return { ...taak }
            }
        })

        setFormValues({ ...formValues, taken: newTaak });
    }

    return (
        <>
            <Items>
                {times.map(item => {
                    return (
                        <Item key={item.id}>
                            <Name>
                                {item.tijd}
                            </Name>
                            <Delete>
                                {
                                    item.checked ?
                                        <ButtonChecked onClick={() => handleSelect(item.id)}>Verwijderen</ButtonChecked>
                                        :
                                        <Button onClick={() => handleSelect(item.id)}>Toevoegen</Button>
                                }
                            </Delete>
                        </Item>
                    )
                })}
            </Items>
        </>
    )
}

export default AgendaTimesCheckbox
