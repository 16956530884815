import FullCalendar from '@fullcalendar/react';
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import nlLocale from "@fullcalendar/core/locales/nl"

const CalendarDDI = (props) => {
    const events = props.events;

    const handleEventClick = (e) => {
        props.setClickedCalendar(true)
        props.setTitle(e.event.title)
        props.setEventId(e.event.id)
        if (props.setEventType) {
            props.setEventType(e.event.extendedProps.typeId)
        }
        if (props.setTaak) {
            props.setTaak(e.event.extendedProps.taken)
        }
        props.setStartTijd(e.event.extendedProps.startTijd)
        props.setEndTijd(e.event.extendedProps.endTijd)
        props.setDescription(e.event.extendedProps.description)
        props.setDayStart(e.event.startStr)
        props.setDayEnd(e.event.endStr)
        if (e.event.end === null) {
            props.setDayEnd(e.event.startStr)
        }

        window.scrollTo(0, 0)
    }
    return (
        <>
            <div className="calendarDDI">
                <FullCalendar
                    locale={nlLocale}
                    timeZone="local"
                    plugins={[daygridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={events}
                    eventClick={handleEventClick}
                    height="auto"
                />
            </div>

        </>
    )
}

export default CalendarDDI
